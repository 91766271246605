import React, {Fragment, useEffect, useState} from "react"
import {Link, Redirect} from "react-router-dom"
import Slider from "react-slick"
import {Col, Row, Button, Form} from 'reactstrap'
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import gql from 'graphql-tag'

import bg1 from "../../assets/utils/images/originals/bg1.jpg"
import bg2 from "../../assets/utils/images/originals/bg2.jpg"
import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import {useAuth} from "../../Context"
import {decodeToken, isToken} from "../../utils/scripts";

const Verify = ({match}) => {
    const {token} = match.params

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }

    const {client, setClient, toast, removeAllToasts} = useAuth()
    const [verify, setVerify] = useState(false)

    useEffect(() => {
        try {
            client.mutate({
                mutation: gql`mutation confirmationEmail($token: String!){
                      emailConfirmation(token: $token) {
                        authentication
                        layout
                      }
                    }`,
                context: {headers: {isAuth: isToken()}},
                variables: {
                    token
                }
            }).then(response => {
                const {emailConfirmation} = response.data

                if (emailConfirmation === null) {
                    toast.error('Token inválido', {autoClose: 10000})
                    return
                }

                setVerify(true)
                setClient(emailConfirmation)
            }).catch(({message}) => {
                toast.error('¡Su token a Expirado!', {autoClose: 5000})
            })
        } catch ({message}) {
            console.log(message)
        }
    }, [client])

    const postVerify = () => {
        toast.success('Reenviando Email...')
        const {email} = decodeToken(token)
        client.query({
            query: gql`query resetVerify($email: String!){
                  verify(email: $email) {
                    id
                  }
                }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                email
            },
            fetchPolicy: "no-cache"
        }).then(response => {
            const {verify} = response.data

            if (verify === null) {
                toast.error('¡Ingrese un email Válido!', {autoClose: 10000})
                return
            }
            removeAllToasts()
            toast.success('¡Te hemos enviado por correo la confirmación de cuenta!', {autoClose: 10000})
        }).catch(({message}) => {
            removeAllToasts()
            toast.error(message, {autoClose: 2000})
        })
    }

    return (
        verify ?
            <Redirect to={'/home'}/>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="8" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg1 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Perfect Balance</h3>
                                            <p>
                                                ArchitectUI is like a dream. Some think it's too good to be true!
                                                Extensive collection of unified React Boostrap Components and Elements.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg3 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Scalable, Modular, Consistent</h3>
                                            <p>
                                                Easily exclude the components you don't require. Lightweight, consistent
                                                Bootstrap based styles across all elements and components
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Complex, but lightweight</h3>
                                            <p>
                                                We've included a lot of components that cover almost all use cases for
                                                any type of application.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="app-logo"/>
                                <h4 className="mb-0">
                                    <div>Bienvenido,</div>
                                    <span>Verificar Cuenta</span>
                                </h4>
                                <h6 className="mt-3">
                                    ¿Ya tienes una cuenta?{' '}
                                    <Link to={'/login'} className="text-primary">Login</Link>
                                </h6>
                                <h6 className="mt-3">
                                    ¿Sin cuenta?{' '}
                                    <Link to={'/register'} className="text-primary">Regístrate ahora</Link>
                                </h6>
                                <Row className="divider"/>
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <div className="no-results">
                                                <div className="results-title text-danger">¡Token caducado, vuelva
                                                    enviar otro correo!
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12} className="text-center">
                                            <Row className="divider"/>
                                            <Button color="primary" size="lg" onClick={postVerify}>
                                                <FontAwesomeIcon icon={faPaperPlane}/> Reenviar Correo de Verificaciónnnnn
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default Verify