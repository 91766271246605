module.exports = {
    HomeNav: [
        {
            id: 'NHO001',
            icon: 'pe-7s-home',
            label: 'Inicio',
            to: '/home'
        }
    ],
    ConsultasNav: [
        {
            id: 'NCO001',
            icon: 'pe-7s-id',
            label: 'DNI',
        },
        {
            id: 'NCO001-1',
            parentId: 'NCO001',
            label: 'Básico',
            to: '/aplicativos/dnibasico',
        },
        {
            id: 'NCO001-2',
            parentId: 'NCO001',
            label: 'Premium',
            to: '/aplicativos/dnipremium',
        },
        {
            id: 'NCO001-3',
            parentId: 'NCO001',
            label: 'Por Nombre',
            to: '/aplicativos/dnipornombre',
        },
        // {
        //     id: 'NCO001-4',
        //     parentId: 'NCO001',
        //     label: 'Platinum',
        //     to: '/aplicativos/dniplatinum',
        // },
        {
            id: 'NCO002',
            icon: 'pe-7s-network',
            label: 'LICENCIAS - MTC',
            to: '/aplicativos/licencias'
        },
        {
            id: 'NCO003',
            icon: 'pe-7s-note2',
            label: 'RUC',
        },
        {
            id: 'NCO003-1',
            parentId: 'NCO003',
            label: 'Reducido',
            to: '/aplicativos/rucreducido',
        },
        {
            id: 'NCO003-2',
            parentId: 'NCO003',
            label: 'Básico',
            to: '/aplicativos/rucbasico',
        },
        {
            id: 'NCO003-3',
            parentId: 'NCO003',
            label: 'Premium',
            to: '/aplicativos/rucpremium',
        },
        {
            id: 'NCO004',
            icon: 'pe-7s-network',
            label: 'ESSALUD',
            to: '/aplicativos/essalud'
        },
        {
            id: 'NCO005',
            icon: 'pe-7s-news-paper',
            label: 'VALIDEZ DE CPE',
        },
        {
            id: 'NCO005-1',
            parentId: 'NCO005',
            label: 'Validez CPE',
            to: '/aplicativos/validez/cpe',
        },
        {
            id: 'NCO005-2',
            parentId: 'NCO005',
            label: 'Validez XML',
            to: '/aplicativos/validez/xml',
        },
        // {
        //     id: 'NCO006',
        //     icon: 'pe-7s-paper-plane',
        //     label: 'CONSULTA CCI - BN',
        //     to: '/aplicativos/cci'
        // },
        {
            id: 'NCO007',
            icon: 'pe-7s-date',
            label: 'TIPO DE CAMBIO',
            to: '/aplicativos/tipocambio'
        },
        {
            id: 'NCO008',
            icon: 'pe-7s-notebook',
            label: 'REMYPE',
            to: '/aplicativos/remype'
        },
        {
            id: 'NCO009',
            icon: 'pe-7s-wallet',
            label: 'OBLIGADOS CPE',
            to: '/aplicativos/obligados'
        },
        {
            id: 'NCO010',
            icon: 'pe-7s-note2',
            label: 'CONSULTAS AFP'
        },
        {
            id: 'NCO010-1',
            parentId: 'NCO010',
            label: 'COMISIONES AFP',
            to: '/aplicativos/cafp'
        },
        {
            id: 'NCO010-2',
            parentId: 'NCO010',
            label: 'AFILIADOS AFP',
            to: '/aplicativos/afafp'
        }
    ],
    SlePle51Nav: [
        {
            id: 'NSP001',
            icon: 'pe-7s-bookmarks',
            label: 'Registro de Compras',
        },
        {
            id: 'NSP001-1',
            parentId: 'NSP001',
            label: 'Compras 8.1',
            to: '/sleple5.1/compras/8.1',
        },
        {
            id: 'NSP001-2',
            parentId: 'NSP001',
            label: 'Compras 8.2',
            to: '/sleple5.1/compras/8.2',
        },
        {
            id: 'NSP001-3',
            parentId: 'NSP001',
            label: 'Compras 8.3',
            to: '/sleple5.1/compras/8.3',
        },
        {
            id: 'NSP002',
            icon: 'pe-7s-notebook',
            label: 'Registro de Ventas',
        },
        {
            id: 'NSP002-1',
            parentId: 'NSP002',
            label: 'Ventas 14.1',
            to: '/sleple5.1/ventas/14.1',
        },
        {
            id: 'NSP002-2',
            parentId: 'NSP002',
            label: 'Ventas 14.2',
            to: '/sleple5.1/ventas/14.2',
        },
        {
            id: 'NSP003',
            icon: 'pe-7s-note2',
            label: 'Libro Diario',
        },
        {
            id: 'NSP003-1',
            parentId: 'NSP003',
            label: 'Libro Diario',
            to: '/sleple5.1/diario/5.1',
        },
        {
            id: 'NSP003-2',
            parentId: 'NSP003',
            label: 'PCGE',
            to: '/sleple5.1/diario/5.3',
        },
        {
            id: 'NSP004',
            icon: 'pe-7s-note2',
            label: 'Libro Diario Simplificado',
        },
        {
            id: 'NSP004-1',
            parentId: 'NSP004',
            label: 'Libro Diario Simplificado',
            to: '/sleple5.1/simplificado/5.2',
        },
        {
            id: 'NSP004-2',
            parentId: 'NSP004',
            label: 'PCGE',
            to: '/sleple5.1/simplificado/5.4',
        },
        {
            id: 'NSP005',
            icon: 'pe-7s-ribbon',
            label: 'Libro Mayor',
            to: '/sleple5.1/mayor/6.1',
        },
    ],
    CompraVentaNav: [
        {
            id: 'NCV001',
            icon: 'pe-7s-cart',
            label: 'Reg. Compras',
            to: '/xml/compras'
        },
        {
            id: 'NCV002',
            icon: 'pe-7s-cart',
            label: 'Reg. Ventas',
            to: '/xml/ventas'
        },
    ],
    GeneradorPdf: [
        {
            id: 'GEN001',
            icon: 'pe-7s-id',
            label: 'Generador PDF',
            to: '/pdf/datos'
        },
    ],
    SisContSimpNav: [
        {
            id: 'SCS001',
            icon: 'pe-7s-culture',
            label: 'EMPRESAS',
            to: '/scs/empresas'
        },
        {
            id: 'SCS002',
            icon: 'pe-7s-usb',
            label: 'DATA',
        },
        {
            id: 'SCS002-1',
            parentId: 'SCS002',
            label: 'Clientes',
            to: '/scs/data/clientes'
        },
        {
            id: 'SCS002-2',
            parentId: 'SCS002',
            label: 'Proveedores',
            to: '/scs/data/proveedores'
        },
        {
            id: 'SCS002-3',
            parentId: 'SCS002',
            label: 'PCGE',
            to: '/scs/data/pcge'
        },
        {
            id: 'SCS002-4',
            parentId: 'SCS002',
            label: 'Destinos',
            to: '/scs/data/destinos'
        },
        {
            id: 'SCS003',
            icon: 'pe-7s-cart',
            label: 'REG. DE OPERACIONES',
        },
        {
            id: 'SCS003-1',
            parentId: 'SCS003',
            label: 'Registro de Compras',
            to: '/scs/operaciones/compras'
        },
        {
            id: 'SCS003-2',
            parentId: 'SCS003',
            label: 'Registro de Ventas',
            to: '/scs/operaciones/ventas'
        },
        {
            id: 'SCS003-3',
            parentId: 'SCS003',
            label: 'Asientos Varios',
            to: '/scs/operaciones/asientosdiarios'
        },
        {
            id: 'SCS003-4',
            parentId: 'SCS003',
            label: 'Cuentas por Pagar',
            to: '/scs/cuentas/pagar'
        },
        {
            id: 'SCS003-5',
            parentId: 'SCS003',
            label: 'Cuentas por Cobrar',
            to: '/scs/cuentas/cobrar'
        },
        {
            id: 'SCS004',
            icon: 'pe-7s-cloud',
            label: 'LIBROS ELECTRÓNICOS',
        },
        {
            id: 'SCS004-1',
            parentId: 'SCS004',
            label: 'Registro de Compras'
        },
        {
            id: 'SCS004-1-1',
            parentId: 'SCS004-1',
            label: 'Compras 8.1',
            to: '/scs/le/compras81'
        },
        {
            id: 'SCS004-1-2',
            parentId: 'SCS004-1',
            label: 'Compras 8.2',
            to: '/scs/le/compras82'
        },
        {
            id: 'SCS004-2',
            parentId: 'SCS004',
            label: 'Registro de Ventas 14.1',
            to: '/scs/le/ventas141'
        },
        {
            id: 'SCS004-3',
            parentId: 'SCS004',
            label: 'Libro Diario'
        },
        {
            id: 'SCS004-3-1',
            parentId: 'SCS004-3',
            label: 'Libro Diario',
            to: '/scs/le/diario51'
        },
        {
            id: 'SCS004-3-2',
            parentId: 'SCS004-3',
            label: 'PCGE',
            to: '/scs/le/pcge53'
        },
        {
            id: 'SCS004-4',
            parentId: 'SCS004',
            label: 'Libro Diario Simplificado'
        },
        {
            id: 'SCS004-4-1',
            parentId: 'SCS004-4',
            label: 'Libro Diario Simplificado',
            to: '/scs/le/diario52'
        },
        {
            id: 'SCS004-4-2',
            parentId: 'SCS004-4',
            label: 'PCGE',
            to: '/scs/le/pcge54'
        },
        {
            id: 'SCS004-5',
            parentId: 'SCS004',
            label: 'Libro Mayor',
            to: '/scs/le/mayor'
        },
        {
            id: 'SCS005',
            icon: 'pe-7s-notebook',
            label: 'LIBROS FÍSICOS',
        },
        {
            id: 'SCS005-1',
            parentId: 'SCS005',
            label: 'Registro de Compras',
            to: '/scs/lf/compras'
        },
        {
            id: 'SCS005-2',
            parentId: 'SCS005',
            label: 'Registro de Ventas',
            to: '/scs/lf/ventas'
        },
        {
            id: 'SCS005-3',
            parentId: 'SCS005',
            label: 'Libro Diario',
            to: '/scs/lf/ldiario'
        },
        {
            id: 'SCS005-4',
            parentId: 'SCS005',
            label: 'Diario Simplificado',
            to: '/scs/lf/diario'
        },
        {
            id: 'SCS005-5',
            parentId: 'SCS005',
            label: 'Libro Mayor',
            to: '/scs/lf/mayor'
        },
        {
            id: 'SCS006',
            icon: 'pe-7s-note2',
            label: 'HOJA DE TRABAJO',
            to: '/scs/hoja-trabajo'
        },
        {
            id: 'SCS007',
            icon: 'pe-7s-cash',
            label: 'ESTADOS FINANCIEROS'
        },
        {
            id: 'SCS007-1',
            parentId: 'SCS007',
            label: 'Balance General',
            to: '/scs/ef/bgc'
        },
        {
            id: 'SCS007-2',
            parentId: 'SCS007',
            label: 'E.G. Y P-Naturaleza',
            to: '/scs/ef/ern'
        },
        {
            id: 'SCS007-3',
            parentId: 'SCS007',
            label: 'E.G. Y P-Función',
            to: '/scs/ef/erf'
        },
        {
            id: 'SCS008',
            icon: 'pe-7s-cloud-download',
            label: 'UTILITARIOS',
        },
        {
            id: 'SCS008-1',
            parentId: 'SCS008',
            label: 'Importar Compras (0801)',
            to: '/scs/utilitarios/importar81'
        },
        {
            id: 'SCS008-2',
            parentId: 'SCS008',
            label: 'Importar Ventas (1401)',
            to: '/scs/utilitarios/importar141'
        },
        {
            id: 'SCS008-3',
            parentId: 'SCS008',
            label: 'Importar Diario (0501)',
            to: '/scs/utilitarios/importar51'
        }
    ],
    ProcesosNav: [
        {
            id: 'NPR001',
            icon: 'pe-7s-cloud-upload',
            label: 'DETRACCIONES (NPD)',
        },
        {
            id: 'NPR001-1',
            parentId: 'NPR001',
            label: 'Proveedor - Adquirientes',
            to: '/operaciones/detraccion/provclie',
        },
        {
            id: 'NPR001-2',
            parentId: 'NPR001',
            label: 'Adquiriente - Proveedores',
            to: '/operaciones/detraccion/clieprov',
        },
        {
            id: 'NPR002',
            icon: 'pe-7s-cloud-upload',
            label: 'ING RECAUDACIÓN F.V 4702',
            to: '/operaciones/detraccion/descargo',
        }
    ],
    PDTsNav: [
        {
            id: 'NPD001',
            icon: 'pe-7s-note2',
            label: 'FORM. VIRTUAL 621',
        },
        {
            id: 'NPD001-1',
            parentId: 'NPD001',
            label: 'RETENCIONES',
            to: '/pdts/retenciones/comprobante-retencion',
        },
        {
            id: 'NPD001-2',
            parentId: 'NPD001',
            label: 'PERCEPCIONES',
        },
        {
            id: 'NPD001-2-1',
            parentId: 'NPD001-2',
            label: 'Percepción en Factura',
            to: '/pdts/percepciones/percepcion-en-factura',
        },
        {
            id: 'NPD001-2-2',
            parentId: 'NPD001-2',
            label: 'Compr. de Percepción',
            to: '/pdts/percepciones/comprobante-percepcion',
        },
        {
            id: 'NPD002',
            icon: 'pe-7s-cloud-upload',
            label: 'PDT 3500 - DAOT',
        },
        {
            id: 'NPD002-1',
            parentId: 'NPD002',
            label: 'Oper. generan Costo/Gasto',
            to: '/pdts/daot/costos',
        },
        {
            id: 'NPD002-2',
            parentId: 'NPD002',
            label: 'Oper. generan Ingreso',
            to: '/pdts/daot/ingresos',
        }
    ],
    AdminNav: [
        {
            id: 'NAD001',
            icon: 'pe-7s-users',
            label: 'Usuarios',
            to: '/admin/users'
        },
        {
            id: 'NAD002',
            icon: 'pe-7s-lock',
            label: 'Autorización',
        },
        {
            id: 'NAD002-1',
            parentId: 'NAD002',
            label: 'Roles',
            to: '/admin/roles'
        },
        {
            id: 'NAD002-2',
            parentId: 'NAD002',
            label: 'Categorias',
            to: '/admin/categorias',
        },
        {
            id: 'NAD002-3',
            parentId: 'NAD002',
            label: 'Servicios',
            to: '/admin/servicios',
            //activeClass: 'active'
        },
        {
            id: 'NAD002-4',
            parentId: 'NAD002',
            label: 'Paquetes',
            to: '/admin/paquetes',
        }

    ],
    LrContablesNav: [
        {
            id: 'LRC001',
            icon: 'pe-7s-note2',
            label: 'Libro Diario',
            to: '/lrcontables/diario-mensual'
        },
        {
            id: 'LRC002',
            icon: 'pe-7s-ribbon',
            label: 'Libro Mayor',
            to: '/lrcontables/libro-mayor'
        },
        {
            id: 'LRC003',
            icon: 'pe-7s-note2',
            label: 'Diario Simplificado',
            to: '/lrcontables/diario-simplificado'
        },
        {
            id: 'LRC004',
            icon: 'pe-7s-map',
            label: 'Hoja de Trabajo',
            to: '/lrcontables/hoja-trabajo'
        },
        {
            id: 'LRC004M1',
            icon: 'pe-7s-display2',
            label: 'Estados Financieros',
        },
        {
            id: 'LRC004M1-1',
            parentId: 'LRC004M1',
            icon: 'pe-7s-display2',
            label: 'Balance General',
            to: '/lrcontables/bgc'
        },
        {
            id: 'LRC004M1-2',
            parentId: 'LRC004M1',
            icon: 'pe-7s-display1',
            label: 'E.G. y P-Naturaleza',
            to: '/lrcontables/ern'
        },
        {
            id: 'LRC004M1-3',
            parentId: 'LRC004M1',
            icon: 'pe-7s-news-paper',
            label: 'E.G. y P-Función',
            to: '/lrcontables/erf'
        },
        {
            id: 'LRC005',
            icon: 'pe-7s-display1',
            label: 'Plan de Cuentas',
        },
        {
            id: 'LRC005-1',
            parentId: 'LRC005',
            label: 'PCGE 2010',
            to: '/lrcontables/pcge/2010',
        },
        {
            id: 'LRC005-2',
            parentId: 'LRC005',
            label: 'PCGE 2020',
            to: '/lrcontables/pcge/2020',
        }
    ],
    SireNav: [
        {
            nro: 0,
            id: 'RVIE000',
            icon: 'pe-7s-shopbag',
            label: 'Empresas',
            to: '/sire/companies',
            category: 'SIRE'
        },
        {
            nro: 0,
            id: 'RVIE001',
            icon: 'pe-7s-shopbag',
            label: 'RVIE 14.4',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-1',
            parentId: 'RVIE001',
            label: 'Resumen',
            to: '/sire/rvie/dashboard',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-2',
            parentId: 'RVIE001',
            label: 'CONTABLE',
            to: '/sire/rvie/contable',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-3',
            parentId: 'RVIE001',
            label: 'SIRE-SUNAT',
            to: '/sire/rvie/sunat',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-4',
            parentId: 'RVIE001',
            label: 'Comparar',
            to: '/sire/rvie/comparar',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-5',
            parentId: 'RVIE001',
            label: 'Ajustes Posteriores',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-5-1',
            parentId: 'RVIE001-5',
            label: 'SIRE',
            to: '/sire/rvie/ajustes/sire',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-5-2',
            parentId: 'RVIE001-5',
            label: 'PLE',
            to: '/sire/rvie/ajustes/ple',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-6',
            parentId: 'RVIE001',
            label: 'Historial',
            to: '/sire/rvie/historial',
            category: 'SIRE'
        },
        {
            nro: 0,
            id: 'RCE001',
            icon: 'pe-7s-cart',
            label: 'RCE',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1',
            parentId: 'RCE001',
            icon: 'pe-7s-shopbag',
            label: 'RCE 8.4',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-1',
            parentId: 'RCE001-1',
            label: 'Resumen',
            to: '/sire/rce/dashboard',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-2',
            parentId: 'RCE001-1',
            label: 'CONTABLE',
            to: '/sire/rce/contable',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-3',
            parentId: 'RCE001-1',
            label: 'SIRE-SUNAT',
            to: '/sire/rce/sunat',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-4',
            parentId: 'RCE001-1',
            label: 'Comparar',
            to: '/sire/rce/comparar',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-5',
            parentId: 'RCE001-1',
            label: 'Ajustes Posteriores',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-5-1',
            parentId: 'RCE001-1-5',
            label: 'SIRE',
            to: '/sire/rce/ajustes/sire',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-5-2',
            parentId: 'RCE001-1-5',
            label: 'PLE',
            to: '/sire/rce/ajustes/ple',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-6',
            parentId: 'RCE001-1',
            label: 'Historial',
            to: '/sire/rce/historial',
            category: 'SIRE'
        },
        {
            id: 'RCE001-2',
            parentId: 'RCE001',
            icon: 'pe-7s-shopbag',
            label: 'RCE-ND (8.5)',
            to: '/sire/rce/ecocont85',
            category: 'SIRE'
        },
        {
            id: 'RVIE002',
            label: 'Generación de Registros',
            to: '/sire/generar',
            icon: 'pe-7s-check',
            category: 'SIRE'
        },
        {
            id: 'RVIE003',
            label: "Lista de Ticket's",
            to: '/sire/ticket',
            icon: 'pe-7s-note2',
            category: 'SIRE'
        }
    ]
}
