import React, {Fragment, useEffect} from "react"
import {Redirect} from "react-router-dom"
import Slider from "react-slick"
import {Col, Row} from 'reactstrap'
import gql from 'graphql-tag'

import bg1 from '../../assets/utils/images/originals/bg1.jpg'
import bg2 from '../../assets/utils/images/originals/bg2.jpg'
import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import {useAuth} from "../../Context"
import {isToken} from "../../utils/scripts"

const AutomaticLogin = ({match}) => {
    const {email, password} = match.params
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }
    const {auth, client, setClient, toast} = useAuth()

    useEffect(() => {
        client.query({
            query: gql`query createToken($email: String!, $password: String!) {
              login(email: $email, password: $password) {
                authentication
                layout
              }
            }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                email,
                password
            },
            fetchPolicy: "no-cache"
        }).then(response => {
            const {login} = response.data
            if (login === null) {
                toast.error('Credenciales incorrectas', {autoClose: 2000})
                return
            }
            const {authentication} = login
            if (authentication === 'verify') {
                toast.error('Aún no ha verificado su email!', {autoClose: 10000})
                return
            }
            setClient(login)
        }).catch(({message}) => {
            toast.error(message, {autoClose: 2000})
        })
    }, [email, password])

    return (
        auth.authentication ?
            <Redirect to={'/home'}/>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="8" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg1 + ')'}}/>
                                        <div className="slider-content">
                                            <h3>Consulta de Validez de CPE</h3>
                                            <p>Consulte la validez de boletas, facturas y sus respectivas notas
                                                electrónicas.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg3 + ')'}}/>
                                        <div className="slider-content">
                                            <h3>Pago masivo de detracciones con NPD</h3>
                                            <p>Operaciones SOL para pago masivo.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta DNI, RUC y EsSalud</h3>
                                            <p>Consultas de reniec, sunat y essalud sin capcha.</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    <img src="/logo.png" alt=""/>
                                    <h4 className="mb-0">
                                        <div>Bienvenido,</div>
                                        <span>ingresando...</span>
                                    </h4>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default AutomaticLogin