import jwt from 'jsonwebtoken'

require('dotenv').config()

Number.prototype.myFixed = function (decimals) {
    const rounding = Math.round(parseFloat(this.toString()) * Math.pow(10, decimals)) / Math.pow(10, decimals)
    return Number(rounding).toFixed(decimals)
}

const download2 = (data, strFileName, strMimeType) => {
    let self = window, // this script is only for browsers anyway...
        defaultMime = "application/octet-stream", // this default mime also triggers iframe downloads
        mimeType = strMimeType || defaultMime,
        payload = data,
        url = !strFileName && !strMimeType && payload,
        anchor = document.createElement("a"),
        toString = function (a) {
            return String(a)
        },
        myBlob = (self.Blob || self.MozBlob || self.WebKitBlob || toString),
        fileName = strFileName || "download",
        blob,
        reader
    myBlob = myBlob.call ? myBlob.bind(self) : Blob

    if (String(this) === "true") { //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
        payload = [payload, mimeType]
        mimeType = payload[0]
        payload = payload[1]
    }

    if (url && url.length < 2048) { // if no filename and no mime, assume a url was passed as the only argument
        fileName = url.split("/").pop().split("?")[0]
        anchor.href = url // assign href prop to temp anchor
        if (anchor.href.indexOf(url) !== -1) { // if the browser determines that it's a potentially valid url path:
            let ajax = new XMLHttpRequest()
            ajax.open("GET", url, true)
            ajax.responseType = 'blob'
            ajax.onload = function (e) {
                download2(e.target.response, fileName, defaultMime)
            }
            setTimeout(function () {
                ajax.send()
            }, 0) // allows setting custom ajax headers using the return:
            return ajax
        } // end if valid url?
    } // end if url?

    //go ahead and download dataURLs right away
    if (/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)) {
        if (payload.length > (1024 * 1024 * 1.999) && myBlob !== toString) {
            payload = dataUrlToBlob(payload)
            mimeType = payload.type || defaultMime
        } else
            return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
                navigator.msSaveBlob(dataUrlToBlob(payload), fileName) :
                saver(payload) // everyone else can save dataURLs un-processed
    }//end if dataURL passed?

    blob = payload instanceof myBlob ? payload : new myBlob([payload], {type: mimeType})

    function dataUrlToBlob(strUrl) {
        let parts = strUrl.split(/[:;,]/),
            type = parts[1],
            decoder = parts[2] === "base64" ? atob : decodeURIComponent,
            binData = decoder(parts.pop()),
            mx = binData.length,
            i = 0,
            uiArr = new Uint8Array(mx)

        for (i; i < mx; ++i) uiArr[i] = binData.charCodeAt(i)

        return new myBlob([uiArr], {type: type})
    }

    function saver(url, winMode) {
        if ('download' in anchor) { //html5 A[download]
            anchor.href = url
            anchor.setAttribute("download", fileName)
            anchor.className = "download-js-link"
            anchor.innerHTML = "downloading..."
            anchor.style.display = "none"
            document.body.appendChild(anchor)
            setTimeout(function () {
                anchor.click()
                document.body.removeChild(anchor)
                if (winMode)
                    setTimeout(function () {
                        self.URL.revokeObjectURL(anchor.href)
                    }, 250)
            }, 66)

            return true
        }

        // handle non-a[download] safari as best we can:
        if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
            url = url.replace(/^data:([\w\/\-\+]+)/, defaultMime)
            if (!window.open(url)) { // popup blocked, offer direct download:
                if (window.confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page."))
                    window.location.href = url
            }

            return true
        }

        //do iframe dataURL download (old ch+FF):
        let f = document.createElement("iframe")
        document.body.appendChild(f)

        if (!winMode) // force a mime that will download:
            url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, defaultMime)

        f.src = url
        setTimeout(function () {
            document.body.removeChild(f)
        }, 333)
    }//end saver

    if (navigator.msSaveBlob) // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(blob, fileName)

    if (self.URL) // simple fast and modern way using Blob and URL:
        saver(self.URL.createObjectURL(blob), true)
    else {
        // handle non-Blob()+non-URL browsers:
        if (typeof blob === "string" || blob.constructor === toString)
            try {
                return saver("data:" + mimeType + ";base64," + self.btoa(blob))
            } catch (y) {
                return saver("data:" + mimeType + "," + encodeURIComponent(blob))
            }

        // Blob but not URL support:
        reader = new FileReader()
        reader.onload = function (e) {
            saver(this.result)
        }
        reader.readAsDataURL(blob)
    }

    return true
}
export const DownloadLink = ({ data, fileName }) => {

    // Convertir base64 a un objeto Blob
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Crear enlace para descargar
    const blobUrl = URL.createObjectURL(fileBlob);

    // Crear un enlace temporal y hacer click programáticamente para descargar
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // Limpiar y remover el enlace
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);

};
export const validateRuc = ruc => {
    const identificador = ruc
    ruc = ruc.trim()

    if (!((ruc >= 1e10 && ruc < 11e9) || (ruc >= 15e9 && ruc < 18e9) || (ruc >= 2e10 && ruc < 21e9)))
        return {rucStatus: false, ruc: identificador, rucMsg: `RUC ${identificador} no válido!`}

    let ultimo = ruc.substring(10, 11)
    let suma = 0

    const factores = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
    factores.forEach((valor, index) => {
        suma += (Number(ruc.substring(index, index + 1)) * valor)
    })

    let di = Math.trunc(suma / 11);
    let resultado = 11 - (Number(suma) - Number(di) * 11)

    if (resultado === 10)
        resultado = 0
    if (resultado === 11)
        resultado = 1

    return Number(ultimo) === resultado ? {
        rucStatus: true,
        ruc: identificador,
        rucMsg: ''
    } : {
        rucStatus: false,
        ruc: identificador,
        rucMsg: `RUC ${identificador} no válido!`
    }
}
export const fileToBase64 = (filename, filepath) => {
    return new Promise(resolve => {
        let file = new File([filename], filepath)
        let reader = new FileReader()
        reader.readAsDataURL(filename)
        // Read file content on file loaded event
        // reader.onload = function () {
        //     resolve(reader.result)
        // }
        reader.onload = event => {
            resolve(event.target.result)
        }
        reader.onerror = error => {
            console.log('Error: ', error)
        }
        // Convert data to base64
        // reader.readAsDataURL(file)
    })
}
export const download = ({file, name}) => {
    let x = new XMLHttpRequest()
    x.open('GET', `${process.env.REACT_APP_API_CONT}/txt/${file}.txt`, true)
    x.responseType = 'blob'
    x.onload = function (e) {
        download2(x.response, `${name}.TXT`, 'text/plain')
    }
    x.send()
}
export const myFormatNumber = (number, withCeros = false, digits = 2) => {
    number = number ? number : 0
    number = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: digits, maximumFractionDigits: digits
    }).format(number ? number : 0)

    return withCeros ? number : (number !== '0.'.padEnd(digits + 2, '0') ? number : '')
}
export const myFormatNumberWithCeros = number => new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(number)
export const validateE_empresa = (empresa, type) => {
    if (empresa[`ruc${type}`] === undefined || empresa[`ruc${type}`] === '')
        return {status: false, message: 'Ingrese el RUC de la Empresa'}
    if (empresa[`periodo${type}`] === undefined || empresa[`periodo${type}`] === '')
        return {status: false, message: 'Ingrese el Periodo'}
    if (empresa[`operacion${type}`] === undefined || empresa[`operacion${type}`] === '')
        return {status: false, message: 'Ingrese el Indicador de Operaciones'}
    if (typeof empresa[`contenido${type}`] !== 'boolean')
        return {status: false, message: 'Ingrese el Indicador de Contenido'}

    return {status: true, message: 'Ok'}
}
export const getLs = key => {
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key)
        // Parse stored json or if none return undefined
        return item ? JSON.parse(item) : undefined
    } catch (error) {
        // If error also return undefined
        return undefined
    }
}
export const decodeToken = token => jwt.decode(token)
export const isToken = (tab = '') => {
    const {pathname} = window.location
    return jwt.sign({sub: 'smartb', pathname, tab}, process.env.REACT_APP_AUTH_JWT_SECRET, {expiresIn: "1800s"})
}
export const uploadImg = (file, type, {authentication}, name = null) => {
    const formData = new FormData();
    formData.append("img", file);
    formData.append("type", type);

    formData.append("name", name ? name.toString().split(".")[0] : null);

    return fetch(`${process.env.REACT_APP_API_CONT}/uploadImg`, {
        method: "POST",
        headers: {
            authentication,
        },
        body: formData,
    });
}
export const utilvalidarRuc = ruc => {
    ruc = ruc.trim()

    if (ruc === null) return {success: false, mensaje: 'Ingrese el número de RUC'}

    if (ruc.length !== 11) return {success: false, mensaje: 'Ha ingresado un RUC con menos de 11 digitos'}

    if (!/^([0-9])*$/.test(ruc)) return {success: false, mensaje: 'Ha ingresado un RUC con letras'}

    if (!((ruc >= 1e10 && ruc < 11e9) || (ruc >= 15e9 && ruc < 18e9) || (ruc >= 2e10 && ruc < 21e9))) return {
        success: false, mensaje: 'RUC no válido!'
    }

    let ultimo = ruc.substring(10, 11)
    let suma = 0
    const factores = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
    factores.forEach((valor, index) => suma += (Number(ruc.substring(index, index + 1)) * valor))

    let di = Math.trunc(suma / 11)
    let resultado = 11 - (Number(suma) - Number(di) * 11)

    if (resultado === 10) resultado = 0
    if (resultado === 11) resultado = 1

    return Number(ultimo) === resultado ? {success: true, mensaje: 'Ok'} : {success: false, mensaje: 'RUC no válido!'}
}

export const downloadTxt = (url, name) => {
    let x = new XMLHttpRequest()
    x.open('GET', url, true)
    x.responseType = 'blob'
    x.onload = function (e) {
        name = name.includes('.txt') || name.includes('.TXT') ? name : `${name}.TXT`
        download2(x.response, name, 'text/plain')
    }
    x.send()
}

export const downloadPdfSire = async (url, nomArchivoReporte) => {
    try {
        const response = await fetch(url)
        const blob = await response.blob()
        const urlr = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = urlr
        link.download = nomArchivoReporte
        link.target = '_blank' // Opcional: abrir en una nueva pestaña
        link.rel = 'noopener noreferrer' // Para la seguridad
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)

        // Liberar recursos después de la descarga
        URL.revokeObjectURL(urlr)
    } catch (error) {
        console.error('Error al descargar el PDF:', error)
    }

    return true
}

export const isPeriod = period => {
    period = period ? period : ''
    return period.length === 7
}

export const validateCompany = company => {
    company = company ? company : {}
    return !!company.ruc
}

export const utilvalidarDni = dni => {
    if (dni === null) return {success: false, mensaje: 'Ingrese el número de DNI'}

    if (dni.length !== 8) return {success: false, mensaje: 'Ha ingresado un DNI con menos de 8 digitos'}

    if (!/^([0-9])*$/.test(dni)) return {success: false, mensaje: 'Ha ingresado letras'}

    return {success: true, mensaje: "Ok"}
}
