import React, {Fragment, useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {Col, Row, Button, Form, FormGroup, Label, Input} from "reactstrap"
import Slider from "react-slick"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPaperPlane, faUserPlus} from "@fortawesome/free-solid-svg-icons"
import gql from "graphql-tag"
import LaddaButton, {EXPAND_RIGHT} from "react-ladda"

import bg3 from '../../assets/utils/images/originals/bg1.jpg'
import bg1 from "../../assets/utils/images/originals/bg2.jpg"
import bg2 from "../../assets/utils/images/originals/bg3.jpg"

import {useAuth} from "../../Context"
import {isToken} from "../../utils/scripts"

const Register = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }

    const {client, removeAllToasts, toast} = useAuth()

    const [user, setUser] = useState({
        email: '',
        name: '',
        apellido_paterno: '',
        apelido_materno: '',
        password: '',
        confirm_password: '',
        telefono: '',
    })
    const [registrado, setRegistrado] = useState(false)
    const [expRight, setExpRight] = useState(false)

    const handleChange = e => {
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const postRegister = () => {
        const {email, name, apellido_paterno, apelido_materno, password, confirm_password, telefono} = user

        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) {
            toast.warning(`La dirección de email "${email}" es incorrecta.`, {autoClose: 2000})
            return
        }

        if (name === '') {
            toast.warning('Ingrese un nobre de Usuario', {autoClose: 2000})
            return
        }

        if (telefono.length < 9) {
            toast.warning('Ingrese un número del teléfono de contacto válido', {autoClose: 2000})
            return
        }

        if (password.length < 6 || password.length > 20) {
            toast.warning('La contraseña debe tener de 5 a 20 caracteres', {autoClose: 5000})
            return
        }

        if (password !== confirm_password) {
            toast.warning('Las contraseñas no son iguales', {autoClose: 5000})
            setUser({
                ...user,
                password: '',
                confirm_password: ''
            })
            return
        }

        setExpRight(true)
        const {origin} = window.location
        let partner_id = 1
        switch (origin) {
            case 'https://www.grupseld.com':
            case 'https://grupseld.com':
                partner_id = 2
                break
            case 'https://www.app.perucontable.net':
            case 'https://app.perucontable.net':
                partner_id = 3
                break
        }

        client.mutate({
            mutation: gql`mutation createUser($name: String!, $apellido_paterno: String!, $apelido_materno: String!, $email: String!, $password: String!, $telefono: String!, $partner_id: Int!) {
                  createUsuario(name: $name, apellido_paterno: $apellido_paterno, apelido_materno: $apelido_materno, email: $email, password: $password, telefono: $telefono, partner_id: $partner_id) {
                    id
                  }
                }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                name, apellido_paterno, apelido_materno, email, password, telefono, partner_id
            }
        }).then(response => {
            const {createUsuario} = response.data

            setExpRight(false)

            if (createUsuario === null) {
                toast.warning(`El correo "${email}" ya esta en uso`, {autoClose: 5000})
                return
            }

            setRegistrado(true)
        }).catch(({message}) => {
            toast.warning(message, {autoClose: 2000})
            setExpRight(false)
        })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter")
                postRegister()
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [user])

    const postVerify = () => {
        toast.success('Reenviando Email...')
        client.query({
            query: gql`query resetVerify($email: String!){
                  verify(email: $email) {
                    id, status
                  }
                }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                email: user.email
            },
            fetchPolicy: "no-cache"
        }).then(response => {
            const {verify} = response.data

            if (verify === null) {
                toast.error('¡Ingrese un email Válido!', {autoClose: 10000})
                return
            }
            removeAllToasts()
            if (verify && verify.status)
                toast.success('¡Te hemos enviado por correo la confirmación de cuenta!', {autoClose: 10000})
            else
                toast.error('Se ha producido un error!!!', {autoClose: 2000})


        }).catch(({message}) => {
            removeAllToasts()
            toast.error(message, {autoClose: 2000})
        })
    }

    return (
        registrado ?
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="7" md="12"
                             className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    <img src="/logo.png" alt="Logo"/>
                                </div>
                                <h4>
                                    <div>Bienvenido,</div>
                                    <br/>
                                    <p style={{textAlign: 'justify'}}>Se ha envido un correo electrónico a la dirección
                                        que has registrado
                                        ({user.email}), por favor ingresa a tu bandeja y confirma tu cuenta, en caso de
                                        no existir ningún correo, revisa tu span o pulsa en el boton reenviar para que
                                        te reenviemos el correo.</p>
                                    <br/>
                                    <p style={{textAlign: 'justify', color: '#dc3545'}}><strong>NOTA: Revise su bandeja
                                        Spam o No deseados.</strong></p>

                                    <span className="text-muted">Mientras no confirmes tu cuenta no podras usar ningún servicio gratuito.</span>
                                </h4>
                                <Col md={12} className="text-center">
                                    <Row className="divider"/>
                                    <Button color="primary" size="lg" onClick={postVerify}>
                                        <FontAwesomeIcon icon={faPaperPlane}/> Reenviar Correo de Verificación
                                    </Button>
                                </Col>
                            </Col>
                        </Col>
                        <Col lg="5" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg1 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta de Validez de CPE</h3>
                                            <p>
                                                Consulte la validez de boletas, facturas y sus respectivas notas
                                                electrónicas.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg3 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Pago masivo de detracciones con NPD</h3>
                                            <p>
                                                Operaciones SOL para pago masivo.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta DNI, RUC y EsSalud</h3>
                                            <p>
                                                Consultas de reniec, sunat y essalud sin capcha.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="7" md="12"
                             className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    <img src="/logo.png" alt="Logo"/>
                                </div>
                                <h4>
                                    <div>Bienvenido,</div>
                                    <span>Solo toma unos <span className="text-success">segundos</span> crear su cuenta</span>
                                </h4>
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>
                                                        Nombres <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="name" value={user.name}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>
                                                        Email <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="email" name="email" value={user.email}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>
                                                        Teléfono de Contacto <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="telefono" value={user.telefono}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} style={{display: 'none'}}>
                                                <FormGroup>
                                                    <Label>
                                                        Apellido Paterno <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="apellido_paterno"
                                                           value={user.apellido_paterno}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} style={{display: 'none'}}>
                                                <FormGroup>
                                                    <Label>
                                                        Apelido Materno <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="apelido_materno"
                                                           value={user.apelido_materno}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>
                                                        Contraseña <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="password" name="password" value={user.password}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>
                                                        Confirmar Contraseña <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="password" name="confirm_password"
                                                           value={user.confirm_password}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="mt-4 d-flex align-items-center">
                                        <h5 className="mb-0">
                                            ¿Ya tienes una cuenta?{' '}
                                            <Link to="/login" className="text-primary">Login</Link>
                                        </h5>
                                        <div className="ml-auto">
                                            <LaddaButton className="btn btn-pill btn-primary"
                                                         loading={expRight} onClick={postRegister}
                                                         data-style={EXPAND_RIGHT}>
                                                <FontAwesomeIcon icon={faUserPlus}/> Crear cuenta
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                        <Col lg="5" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg1 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta de Validez de CPE</h3>
                                            <p>
                                                Consulte la validez de boletas, facturas y sus respectivas notas
                                                electrónicas.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg3 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Pago masivo de detracciones con NPD</h3>
                                            <p>
                                                Operaciones SOL para pago masivo.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta DNI, RUC y EsSalud</h3>
                                            <p>
                                                Consultas de reniec, sunat y essalud sin capcha.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default Register