import React, {Fragment, useState} from 'react'
import {
    Button, Card, CardFooter, CardHeader, Col, Row, UncontrolledButtonDropdown, DropdownItem, DropdownToggle,
    DropdownMenu, Progress, Popover, PopoverBody, Nav, NavItem, NavLink, Table, ButtonGroup
} from "reactstrap"
import CardBody from 'reactstrap/lib/CardBody';
import {faAngleUp, faAngleDown, faBusinessTime, faCog} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Chart from 'react-apexcharts'
import classnames from 'classnames'

// Layout
import AppHeader from '../Layout/AppHeader'
import AppSidebar from '../Layout/AppSidebar'
import AppFooter from '../Layout/AppFooter'
// Theme Options
import ThemeOptions from '../Layout/ThemeOptions'

require('dotenv').config()

const Home = props => {
    const [optionsMixedChart1, setOptionsMixedChart1] = useState({
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: [0, 4]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001',
            '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001',
            '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
            type: 'datetime'
        },
        yaxis: [{
            title: {
                text: 'Compras',
            },

        }, {
            opposite: true,
            title: {
                text: 'Ventas'
            }
        }]
    })

    const [seriesMixedChart1, setSeriesMixedChart1] = useState([
        {
            name: 'Compras',
            type: 'column',
            data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
        },
        {
            name: 'Ventas',
            type: 'line',
            data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        }
    ])

    const [optionsRadial, setOptionsRadial] = useState({
        chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
                show: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                    }
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    margin: 0, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35
                    }
                },

                dataLabels: {
                    showOn: 'always',
                    name: {
                        offsetY: -10,
                        show: true,
                        color: '#888',
                        fontSize: '17px'
                    },
                    value: {
                        formatter: function (val) {
                            return parseInt(val);
                        },
                        color: '#111',
                        fontSize: '36px',
                        show: true,
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#ABE5A1'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        },
        labels: ['Percent'],

    })

    const [seriesRadial, setSeriesRadial] = useState([76])
    const [options66, setOptions66] = useState({
        chart: {
            height: 300,
            type: 'bar',
            stacked: true,
            sparkline: {
                enabled: true,
            }
        },
        colors: ['var(--success)', 'var(--danger)'],
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '80%',

            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1,
            colors: ["#fff"]
        },
        grid: {
            xaxis: {
                showLines: false
            }
        },
        yaxis: {
            min: -5,
            max: 5,
            title: {
                // text: 'Age',
            },
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return val
                }
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val) + "%"
                }
            }
        },
        xaxis: {
            categories: ['85+', '80-84', '75-79', '70-74', '65-69', '60-64', '55-59', '50-54', '45-49', '40-44', '35-39', '30-34', '25-29', '20-24', '15-19', '10-14', '5-9', '0-4'],
            title: {
                text: 'Percent'
            },
            labels: {
                formatter: function (val) {
                    return Math.abs(Math.round(val)) + "%"
                }
            }
        },
    })

    const [series66, setSeries66] = useState([
        {
            name: 'Males',
            data: [0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1, 4.2, 4.5, 3.9, 3.5, 3]
        },
        {
            name: 'Females',
            data: [-0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22, -4.3, -4.4, -4.1, -4, -4.1, -3.4, -3.1, -2.8]
        }
    ])

    const [options55, setOptions55] = useState({
        chart: {
            height: 350,
            type: 'bar',
            sparkline: {
                enabled: true,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '55%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
        yaxis: {
            title: {
                text: '$ (thousands)'
            }
        },
        fill: {
            opacity: 1

        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }
    })

    const [series55, setSeries55] = useState([
        {
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }
    ])

    const [options77, setOptions77] = useState({
        chart: {
            height: 350,
            type: 'area',
            sparkline: {
                enabled: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },

        xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00"],
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        }
    })

    const [series77, setSeries77] = useState([
        {
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
        }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
        }
    ])

    const [popoverOpen1, setpopoverOpen1] = useState(false)

    const togglePop1 = () => {
        setpopoverOpen1(!popoverOpen1)
    }

    return (
        <Fragment>
            <ThemeOptions/>
            <AppHeader/>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Card className="">
                            {/* <img src="/logo.png" style={{position: "absolute", left: 0, top: 0, width: "100%", height: "auto", opacity: 0.2}}></img> */}
                            <CardBody className="pt-1 pb-0">
                                <Row>
                                    <Col md="6" lg="3">
                                        <Card
                                            className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <div className="widget-title text-uppercase">
                                                        <b>Sistema Contable</b>
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="text-center">
                                                            {/* <Link to="/scs/empresas" className="btn-lg btn btn-link"> Ir a</Link> */}
                                                            <Button className="ml-1 btn-lg" color="primary"
                                                                    onClick={() => props.history.push("/scs/empresas")}>
                                                                Ir a
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col md="6" lg="3">
                                        <Card
                                            className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-info border-info">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <div className="widget-title text-uppercase">
                                                        <b>Consultas Online</b>
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="text-center">
                                                            <Button className="ml-1 btn-lg" color="primary"
                                                                    onClick={() => props.history.push("/aplicativos/dnibasico")}>
                                                                Ir a
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col md="6" lg="3">
                                        <Card
                                            className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning border-warning">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <div className="widget-title text-uppercase">
                                                        <b>Libros Electrónicos</b>
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="text-center">
                                                            <Button className="ml-1 btn-lg" color="primary"
                                                                    onClick={() => props.history.push("/sleple5.1/compras/8.1")}>
                                                                Ir a
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col md="6" lg="3">
                                        <Card
                                            className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-success border-success">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <div className="widget-title text-uppercase">
                                                        <b>Varios</b>
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="text-center">
                                                            <Button className="ml-1 btn-lg" color="primary"
                                                                    onClick={() => props.history.push("/operaciones/detraccion/provclie")}>
                                                                Ir a
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="7" lg="8">
                                        <Card className="mb-3">
                                            <CardHeader className="card-header-tab">
                                                <div
                                                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                    Compras/Ventas
                                                </div>

                                                {/* <div className="btn-actions-pane-right text-capitalize">
                            <Button color="warning">Actions</Button>
                        </div> */}
                                            </CardHeader>
                                            <CardBody className="pt-0">
                                                {/* <Mixed/> */}
                                                <div className="bar">
                                                    <Chart options={optionsMixedChart1} series={seriesMixedChart1}
                                                           type="line" width="100%" height="325px"/>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="12" md="5" lg="4">
                                        <Card className="mb-3">
                                            <CardHeader className="card-header-tab">
                                                <div
                                                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                    Income
                                                </div>
                                                <div
                                                    className="btn-actions-pane-right text-capitalize actions-icon-btn">
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle className="btn-icon btn-icon-only" color="link">
                                                            <i className="lnr-cog btn-icon-wrapper"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu right
                                                                      className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                                                            <DropdownItem header>Header</DropdownItem>
                                                            <DropdownItem>
                                                                <i className="dropdown-icon lnr-inbox"> </i>
                                                                <span>Menus</span>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <i className="dropdown-icon lnr-file-empty"> </i>
                                                                <span>Settings</span>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <i className="dropdown-icon lnr-book"> </i>
                                                                <span>Actions</span>
                                                            </DropdownItem>
                                                            <DropdownItem divider/>
                                                            <div className="p-1 text-right">
                                                                <Button className="mr-2 btn-shadow btn-sm" color="link">View
                                                                    Details</Button>
                                                                <Button className="mr-2 btn-shadow btn-sm"
                                                                        color="primary">Action</Button>
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <Chart options={optionsRadial} series={seriesRadial} type="radialBar"
                                                       height={270}/>
                                                <div className="widget-content pt-0 w-100">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left pr-2 fsize-1">
                                                                <div
                                                                    className="widget-numbers mt-0 fsize-3 text-warning">
                                                                    32%
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right w-100">
                                                                <Progress
                                                                    className="progress-bar-xs"
                                                                    color="warning"
                                                                    value="32"/>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-left fsize-1">
                                                            <div className="text-muted opacity-6">
                                                                Spendings Target
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <h6 className="widget-subheading">
                                                        Income
                                                    </h6>
                                                    <div className="widget-chart-flex">
                                                        <div className="widget-numbers mb-0 w-100">
                                                            <div className="widget-chart-flex">
                                                                <div className="fsize-4">
                                                                    <small className="opacity-5">$</small>
                                                                    5,456
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div
                                                                        className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                    <span className="text-success pl-2">
                                        +14%
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <h6 className="widget-subheading">
                                                        Expenses
                                                    </h6>
                                                    <div className="widget-chart-flex">
                                                        <div className="widget-numbers mb-0 w-100">
                                                            <div className="widget-chart-flex">
                                                                <div className="fsize-4 text-danger">
                                                                    <small className="opacity-5 text-muted">$</small>
                                                                    4,764
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div
                                                                        className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                    <span className="text-danger pl-2">
                                      <span className="pr-1">
                                          <FontAwesomeIcon icon={faAngleUp}/>
                                      </span>
                                        8%
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <h6 className="widget-subheading">
                                                        Spendings
                                                    </h6>
                                                    <div className="widget-chart-flex">
                                                        <div className="widget-numbers mb-0 w-100">
                                                            <div className="widget-chart-flex">
                                                                <div className="fsize-4">
                                  <span className="text-success pr-2">
                                      <FontAwesomeIcon icon={faAngleDown}/>
                                  </span>
                                                                    <small className="opacity-5">$</small>
                                                                    1.5M
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div
                                                                        className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                    <span className="text-success pl-2">
                                      <span className="pr-1">
                                          <FontAwesomeIcon icon={faAngleDown}/>
                                      </span>
                                      15%
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content">
                                                    <h6 className="widget-subheading">
                                                        Totals
                                                    </h6>
                                                    <div className="widget-chart-flex">
                                                        <div className="widget-numbers mb-0 w-100">
                                                            <div className="widget-chart-flex">
                                                                <div className="fsize-4">
                                                                    <small className="opacity-5">$</small>
                                                                    31,564
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div
                                                                        className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                    <span className="text-warning pl-2">
                                        +76%
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>

                                <CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
                                    <div className="card-header-title fsize-2 text-capitalize font-weight-normal">
                                        Target Section
                                    </div>

                                    <div className="btn-actions-pane-right text-capitalize actions-icon-btn">
                                        <Button size="sm" color="link">View Details</Button>
                                    </div>
                                </CardHeader>

                                <Row>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-content p-0 w-100">
                                                <div className="widget-content-outer">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left pr-2 fsize-1">
                                                            <div className="widget-numbers mt-0 fsize-3 text-danger">
                                                                71%
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right w-100">
                                                            <Progress
                                                                className="progress-bar-xs"
                                                                color="danger"
                                                                value="71"/>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-left fsize-1">
                                                        <div className="text-muted opacity-6">
                                                            Income Target
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-content p-0 w-100">
                                                <div className="widget-content-outer">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left pr-2 fsize-1">
                                                            <div className="widget-numbers mt-0 fsize-3 text-success">
                                                                54%
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right w-100">
                                                            <Progress
                                                                className="progress-bar-xs"
                                                                color="success"
                                                                value="54"/>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-left fsize-1">
                                                        <div className="text-muted opacity-6">
                                                            Expenses Target
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-content p-0 w-100">
                                                <div className="widget-content-outer">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left pr-2 fsize-1">
                                                            <div className="widget-numbers mt-0 fsize-3 text-warning">
                                                                32%
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right w-100">
                                                            <Progress
                                                                className="progress-bar-xs"
                                                                color="warning"
                                                                value="32"/>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-left fsize-1">
                                                        <div className="text-muted opacity-6">
                                                            Spendings Target
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
                                            <div className="widget-content p-0 w-100">
                                                <div className="widget-content-outer">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left pr-2 fsize-1">
                                                            <div className="widget-numbers mt-0 fsize-3 text-info">
                                                                89%
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right w-100">
                                                            <Progress
                                                                className="progress-bar-xs"
                                                                color="info"
                                                                value="89"/>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-left fsize-1">
                                                        <div className="text-muted opacity-6">
                                                            Totals Target
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" lg="4">
                                        <Card className="mb-3">
                                            <CardHeader className="card-header-tab">
                                                <div
                                                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                    Total Sales
                                                </div>

                                                <div
                                                    className="btn-actions-pane-right text-capitalize actions-icon-btn">
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle className="btn-icon btn-icon-only" color="link">
                                                            <i className="lnr-cog btn-icon-wrapper"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                                                            <DropdownItem header>Header</DropdownItem>
                                                            <DropdownItem>
                                                                <i className="dropdown-icon lnr-inbox"> </i>
                                                                <span>Menus</span>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <i className="dropdown-icon lnr-file-empty"> </i>
                                                                <span>Settings</span>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <i className="dropdown-icon lnr-book"> </i>
                                                                <span>Actions</span>
                                                            </DropdownItem>
                                                            <DropdownItem divider/>
                                                            <div className="p-1 text-right">
                                                                <Button className="mr-2 btn-shadow btn-sm" color="link">View
                                                                    Details</Button>
                                                                <Button className="mr-2 btn-shadow btn-sm"
                                                                        color="primary">Action</Button>
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                {/* <Bar2/> */}
                                                <div className="bar">
                                                    <Chart options={options66} series={series66} type="bar"
                                                           width="100%"/>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="p-0 d-block">
                                                <div className="grid-menu grid-menu-2col">
                                                    <Row className="no-gutters">
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-car text-primary opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Admin
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-bullhorn text-danger opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Blog
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-bug text-success opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Register
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-heart text-warning opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Directory
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Card className="mb-3">
                                            <CardHeader className="card-header-tab">
                                                <div
                                                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                    Daily Sales
                                                </div>

                                                <div className="btn-actions-pane-right text-capitalize">
                                                    <Button size="sm" outline className="btn-wide btn-outline-2x"
                                                            color="focus">View All</Button>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                {/* <Column/> */}
                                                <div className="column">
                                                    <Chart options={options55} series={series55} type="bar"
                                                           width="100%"/>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="p-0 d-block">
                                                <div className="grid-menu grid-menu-2col">
                                                    <Row className="no-gutters">
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-apartment text-dark opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Overview
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-database text-dark opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Support
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-printer text-dark opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Activities
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="dark">
                                                                <i className="lnr-store text-dark opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Marketing
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Card className="mb-3">
                                            <CardHeader className="card-header-tab">
                                                <div
                                                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                    Total Expenses
                                                </div>

                                                <div className="btn-actions-pane-right text-capitalize">
                                                    <Button size="sm" outline className="btn-wide btn-outline-2x"
                                                            color="primary">View All</Button>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                {/* <Area/> */}
                                                <div className="bar">
                                                    <Chart options={options77} series={series77} type="bar"
                                                           width="100%"/>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="p-0 d-block">
                                                <div className="grid-menu grid-menu-2col">
                                                    <Row className="no-gutters">
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="success">
                                                                <i className="lnr-lighter text-success opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Accounts
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="warning">
                                                                <i className="lnr-construction text-warning opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Contacts
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="info">
                                                                <i className="lnr-bus text-info opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Products
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6" className="p-2">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="alternate">
                                                                <i className="lnr-gift text-alternate opacity-7 btn-icon-wrapper mb-2"> </i>
                                                                Services
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>

                                <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div
                                            className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                            Company Agents Status
                                        </div>
                                        <div className="btn-actions-pane-right">
                                            <Button size="sm" outline className="btn-icon btn-wide btn-outline-2x"
                                                    id={'PopoverCustomT-1'}
                                                    onClick={togglePop1} color="focus">
                                                Actions Menu
                                                <span className="pl-2 align-middle opacity-7">
                              <FontAwesomeIcon icon={faAngleDown}/>
                          </span>
                                            </Button>
                                            <Popover className="popover-custom rm-pointers" placement="auto"
                                                     isOpen={popoverOpen1}
                                                     target={'PopoverCustomT-1'} toggle={togglePop1}>
                                                <PopoverBody>
                                                    <div className="dropdown-menu-header">
                                                        <div className={classnames(
                                                            "dropdown-menu-header-inner bg-focus")}>
                                                            <div className="menu-header-image"
                                                                // style={{
                                                                //     backgroundImage: 'url(' + bg1 + ')'
                                                                // }}
                                                            />
                                                            <div className="menu-header-content">
                                                                <h5 className="menu-header-title">Settings</h5>
                                                                <h6 className="menu-header-subtitle">Manage all of your
                                                                    options</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Nav vertical>
                                                        <NavItem className="nav-item-header">
                                                            Activity
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink href="javascript:void(0);">
                                                                Chat
                                                                <div
                                                                    className="ml-auto badge badge-pill badge-info">8</div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink href="javascript:void(0);">Recover
                                                                Password</NavLink>
                                                        </NavItem>
                                                        <NavItem className="nav-item-divider"/>
                                                        <NavItem className="nav-item-btn text-center">
                                                            <Button size="sm" className="btn-wide btn-shadow"
                                                                    color="danger">
                                                                Cancel
                                                            </Button>
                                                        </NavItem>
                                                    </Nav>
                                                </PopoverBody>
                                            </Popover>
                                        </div>
                                    </CardHeader>
                                    <Table responsive borderless hover className="align-middle text-truncate mb-0">
                                        <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th className="text-center">Avatar</th>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Company</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Due Date</th>
                                            <th className="text-center">Target Achievement</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="text-center text-muted" style={{width: '80px'}}>#54</td>
                                            <td className="text-center" style={{width: '80px'}}>
                                                <img width={40} className="rounded-circle"
                                                    // src={avatar1}
                                                     alt=""/>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Juan C. Cargill
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Micro Electronics
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-pill badge-danger">Canceled</div>
                                            </td>
                                            <td className="text-center">
                          <span className="pr-2 opacity-6">
                              <FontAwesomeIcon icon={faBusinessTime}/>
                          </span>
                                                12 Dec
                                            </td>
                                            <td className="text-center" style={{width: '200px'}}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left pr-2">
                                                                <div className="widget-numbers fsize-1 text-danger">
                                                                    71%
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right w-100">
                                                                <Progress
                                                                    className="progress-bar-xs"
                                                                    color="danger"
                                                                    value="71"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <ButtonGroup size="sm">
                                                    <Button className="btn-shadow" color="primary">Hire</Button>
                                                    <Button className="btn-shadow" color="primary">Fire</Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center text-muted" style={{width: '80px'}}>#55</td>
                                            <td className="text-center" style={{width: '80px'}}>
                                                <img width={40} className="rounded-circle"
                                                    // src={avatar2}
                                                     alt=""/>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Johnathan Phelan
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Hatchworks
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-pill badge-info">On Hold</div>
                                            </td>
                                            <td className="text-center">
                          <span className="pr-2 opacity-6">
                              <FontAwesomeIcon icon={faBusinessTime}/>
                          </span>
                                                15 Dec
                                            </td>
                                            <td className="text-center" style={{width: '200px'}}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left pr-2">
                                                                <div className="widget-numbers fsize-1 text-warning">
                                                                    54%
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right w-100">
                                                                <Progress
                                                                    className="progress-bar-xs"
                                                                    color="warning"
                                                                    value="54"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <ButtonGroup size="sm">
                                                    <Button className="btn-shadow" color="primary">Hire</Button>
                                                    <Button className="btn-shadow" color="primary">Fire</Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center text-muted" style={{width: '80px'}}>#56</td>
                                            <td className="text-center" style={{width: '80px'}}>
                                                <img width={40} className="rounded-circle"
                                                    // src={avatar3}
                                                     alt=""/>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Darrell Lowe
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Riddle Electronics
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-pill badge-warning">In Progress</div>
                                            </td>
                                            <td className="text-center">
                          <span className="pr-2 opacity-6">
                              <FontAwesomeIcon icon={faBusinessTime}/>
                          </span>
                                                6 Dec
                                            </td>
                                            <td className="text-center" style={{width: '200px'}}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left pr-2">
                                                                <div className="widget-numbers fsize-1 text-success">
                                                                    97%
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right w-100">
                                                                <Progress
                                                                    className="progress-bar-xs"
                                                                    color="success"
                                                                    value="97"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <ButtonGroup size="sm">
                                                    <Button className="btn-shadow" color="primary">Hire</Button>
                                                    <Button className="btn-shadow" color="primary">Fire</Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center text-muted" style={{width: '80px'}}>#56</td>
                                            <td className="text-center" style={{width: '80px'}}>
                                                <img width={40} className="rounded-circle"
                                                    // src={avatar4}
                                                     alt=""/>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    George T. Cottrell
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <a href="javascript:void(0)">
                                                    Pixelcloud
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-pill badge-success">Completed</div>
                                            </td>
                                            <td className="text-center">
                          <span className="pr-2 opacity-6">
                              <FontAwesomeIcon icon={faBusinessTime}/>
                          </span>
                                                19 Dec
                                            </td>
                                            <td className="text-center" style={{width: '200px'}}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left pr-2">
                                                                <div className="widget-numbers fsize-1 text-info">
                                                                    88%
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right w-100">
                                                                <Progress
                                                                    className="progress-bar-xs"
                                                                    color="info"
                                                                    value="88"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <ButtonGroup size="sm">
                                                    <Button className="btn-shadow" color="primary">Hire</Button>
                                                    <Button className="btn-shadow" color="primary">Fire</Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <CardFooter className="d-block p-4 text-center">
                                        <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                        <span className="mr-2 opacity-7">
                            <FontAwesomeIcon spin fixedWidth={false} icon={faCog}/>
                        </span>
                                            <span className="mr-1">
                                View Complete Report
                        </span>
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </CardBody>

                        </Card>
                    </div>
                    <AppFooter/>
                </div>
            </div>
        </Fragment>
    )
}

export default Home
