import React, {useState} from 'react'
import {connect} from 'react-redux'
import cx from 'classnames'
import {Button, ListGroup, ListGroupItem, ButtonGroup, UncontrolledTooltip} from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faCheck} from '@fortawesome/free-solid-svg-icons'
import gql from "graphql-tag"

import {
    setBackgroundColor, setColorScheme, setBackgroundImage, setBackgroundImageOpacity, setEnableBackgroundImage,
    setEnableFixedHeader, setEnableHeaderShadow, setEnableSidebarShadow, setEnableFixedSidebar, setEnableFixedFooter,
    setHeaderBackgroundColor, setEnablePageTitleSubheading, setEnablePageTabsAlt, setEnablePageTitleIcon,
} from '../../reducers/ThemeOptions'
import {useAuth} from "../../Context"

import sideBar1 from '../../assets/utils/images/sidebar/abstract1.jpg'
import sideBar2 from '../../assets/utils/images/sidebar/abstract2.jpg'
import sideBar3 from '../../assets/utils/images/sidebar/abstract3.jpg'
import sideBar4 from '../../assets/utils/images/sidebar/abstract4.jpg'
import sideBar5 from '../../assets/utils/images/sidebar/abstract5.jpg'
import sideBar6 from '../../assets/utils/images/sidebar/city1.jpg'
import sideBar7 from '../../assets/utils/images/sidebar/city2.jpg'
import sideBar8 from '../../assets/utils/images/sidebar/city3.jpg'
import sideBar9 from '../../assets/utils/images/sidebar/city4.jpg'
import sideBar10 from '../../assets/utils/images/sidebar/city5.jpg'
import {decodeToken, isToken} from "../../utils/scripts"

const ThemeOptions = props => {
    const {client, auth, setAuth, toast} = useAuth()
    const [showing, setShowing] = useState(false)

    let {authentication, layout} = auth
    const {user_id} = decodeToken(authentication)

    const saveLayout = (name, value) => {
        layout = {
            ...layout,
            [name]: value
        }

        setAuth({...auth, layout})

        client
            .mutate({
                mutation: gql`mutation saveLayout($id: Int!, $update: JSON!){
                updateUsuario(id: $id, update: $update) {
                    layout
                  }
                }`,
                context: {headers: {isAuth: isToken()}},
                variables: {
                    id: Number(user_id),
                    update: {
                        layout
                    }
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch(({message}) => {
                toast.error(message, {autoClose: 2000})
            })
    }

    const toggleEnableBackgroundImage = () => {
        let {enableBackgroundImage} = layout
        let {setEnableBackgroundImage} = props
        setEnableBackgroundImage(!enableBackgroundImage)
        saveLayout('enableBackgroundImage', !enableBackgroundImage)
    }

    const toggleEnableFixedHeader = () => {
        let {enableFixedHeader} = layout
        let {setEnableFixedHeader} = props
        setEnableFixedHeader(!enableFixedHeader)
        saveLayout('enableFixedHeader', !enableFixedHeader)
    }

    const toggleEnableHeaderShadow = () => {
        let {enableHeaderShadow} = layout
        let {setEnableHeaderShadow} = props
        setEnableHeaderShadow(!enableHeaderShadow)
        saveLayout('enableHeaderShadow', !enableHeaderShadow)
    }

    const toggleEnableSidebarShadow = () => {
        let {enableSidebarShadow} = layout
        let {setEnableSidebarShadow} = props
        setEnableSidebarShadow(!enableSidebarShadow)
        saveLayout('enableSidebarShadow', !enableSidebarShadow)
    }

    const toggleEnableFixedSidebar = () => {
        let {enableFixedSidebar} = layout
        let {setEnableFixedSidebar} = props
        setEnableFixedSidebar(!enableFixedSidebar)
        saveLayout('enableFixedSidebar', !enableFixedSidebar)
    }

    const toggleEnablePageTitleIcon = () => {
        let {enablePageTitleIcon} = layout
        let {setEnablePageTitleIcon} = props
        setEnablePageTitleIcon(!enablePageTitleIcon)
        saveLayout('enablePageTitleIcon', !enablePageTitleIcon)
    }

    const toggleEnablePageTitleSubheading = () => {
        let {enablePageTitleSubheading} = layout
        let {setEnablePageTitleSubheading} = props
        setEnablePageTitleSubheading(!enablePageTitleSubheading)
        saveLayout('enablePageTitleSubheading', !enablePageTitleSubheading)
    }

    const toggleEnablePageTabsAlt = () => {
        let {enablePageTabsAlt} = layout
        let {setEnablePageTabsAlt} = props
        setEnablePageTabsAlt(!enablePageTabsAlt)
        saveLayout('enablePageTabsAlt', !enablePageTabsAlt)
    }

    const toggleEnableFixedFooter = () => {
        let {enableFixedFooter} = layout
        let {setEnableFixedFooter} = props
        setEnableFixedFooter(!enableFixedFooter)
        saveLayout('enableFixedFooter', !enableFixedFooter)
    }

    const toggleHeaderBackgroundColor = headerBackgroundColor => {
        let {setHeaderBackgroundColor} = props
        setHeaderBackgroundColor(headerBackgroundColor)
        saveLayout('headerBackgroundColor', headerBackgroundColor)
    }

    const toggleBackgroundColor = backgroundColor => {
        let {setBackgroundColor} = props
        setBackgroundColor(backgroundColor)
        saveLayout('backgroundColor', backgroundColor)
    }

    const toggleBackgroundImageOpacity = backgroundImageOpacity => {
        let {setBackgroundImageOpacity} = props
        setBackgroundImageOpacity(backgroundImageOpacity)
        saveLayout('backgroundImageOpacity', backgroundImageOpacity)
    }

    const toggleBackgroundImage = backgroundImage => {
        let {setBackgroundImage} = props
        setBackgroundImage(backgroundImage)
        saveLayout('backgroundImage', backgroundImage)
    }

    const toggleColorScheme = colorScheme => {
        let {setColorScheme} = props
        setColorScheme(colorScheme)
        saveLayout('colorScheme', colorScheme)
    }

    let {
        backgroundColor,
        headerBackgroundColor,
        colorScheme,
        backgroundImageOpacity,
        enableFixedHeader,
        enableHeaderShadow,
        enableSidebarShadow,
        enableFixedSidebar,
        enableFixedFooter,
        enablePageTitleIcon,
        enablePageTitleSubheading,
        enablePageTabsAlt,
        enableBackgroundImage,
        backgroundImage
    } = layout

    return (''
        // <div className={"ui-theme-settings " + (showing ? 'settings-open' : '')}>
        //     <Button className="btn-open-options" id="TooltipDemo" color="primary" onClick={() => setShowing(!showing)}>
        //         <FontAwesomeIcon icon={faCog} spin color="#fafafa" fixedWidth={false} size="2x"/>
        //     </Button>
        //     <UncontrolledTooltip placement="left" target={'TooltipDemo'}>
        //         Configuración
        //     </UncontrolledTooltip>
        //     <div className="theme-settings__inner">
        //         <PerfectScrollbar>
        //             <div className="theme-settings__options-wrapper">
        //                 <h3 className="themeoptions-heading">
        //                     Opciones de diseño
        //                 </h3>
        //                 <div className="p-3">
        //                     <ListGroup>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnableFixedHeader}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enableFixedHeader,
        //                                                 'switch-off': !enableFixedHeader
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Encabezado fijo
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             Hace que la parte superior del encabezado sea fija, ¡siempre
        //                                             visible!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enableFixedHeader,
        //                                         'd-none': !enableFixedHeader
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnableFixedSidebar}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enableFixedSidebar,
        //                                                 'switch-off': !enableFixedSidebar
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Barra lateral fija
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             Hace que la barra lateral quede fija, ¡siempre visible!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enableFixedSidebar,
        //                                         'd-none': !enableFixedSidebar
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnableFixedFooter}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enableFixedFooter,
        //                                                 'switch-off': !enableFixedFooter
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Pie de página fijo
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             Hace que el pie de página de la aplicación sea fijo, ¡siempre
        //                                             visible!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enableFixedFooter,
        //                                         'd-none': !enableFixedFooter
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                     </ListGroup>
        //                 </div>
        //                 <h3 className="themeoptions-heading">
        //                     <div>Opciones de encabezado</div>
        //                     <Button size="sm" color="focus"
        //                             className={cx("btn-pill btn-shadow btn-wide ml-auto", {active: headerBackgroundColor === ''})}
        //                             onClick={() => toggleHeaderBackgroundColor('')}>
        //                         Restaurar Predeterminado
        //                     </Button>
        //                 </h3>
        //                 <div className="p-3">
        //                     <ListGroup>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnableHeaderShadow}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enableHeaderShadow,
        //                                                 'switch-off': !enableHeaderShadow
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Encabezado Sombra
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             Añadir una sombra para el encabezado!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enableHeaderShadow,
        //                                         'd-none': !enableHeaderShadow
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <h5 className="pb-2">Elegir esquema de color</h5>
        //                             <div className="theme-settings-swatches">
        //                                 <div
        //                                     className={cx("swatch-holder bg-primary", {active: headerBackgroundColor === 'bg-primary header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-primary header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-secondary", {active: headerBackgroundColor === 'bg-secondary header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-secondary header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-success", {active: headerBackgroundColor === 'bg-success header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-success header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-info", {active: headerBackgroundColor === 'bg-info header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-info header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-warning", {active: headerBackgroundColor === 'bg-warning header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-warning header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-danger", {active: headerBackgroundColor === 'bg-danger header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-danger header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-light", {active: headerBackgroundColor === 'bg-light header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-light header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-dark", {active: headerBackgroundColor === 'bg-dark header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-dark header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-focus", {active: headerBackgroundColor === 'bg-focus header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-focus header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-alternate", {active: headerBackgroundColor === 'bg-alternate header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-alternate header-text-light")}/>
        //
        //                                 <div className="divider"/>
        //
        //                                 <div
        //                                     className={cx("swatch-holder bg-vicious-stance", {active: headerBackgroundColor === 'bg-vicious-stance header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-vicious-stance header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-midnight-bloom", {active: headerBackgroundColor === 'bg-midnight-bloom header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-midnight-bloom header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-night-sky", {active: headerBackgroundColor === 'bg-night-sky header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-night-sky header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-slick-carbon", {active: headerBackgroundColor === 'bg-slick-carbon header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-slick-carbon header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-asteroid", {active: headerBackgroundColor === 'bg-asteroid header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-asteroid header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-royal", {active: headerBackgroundColor === 'bg-royal header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-royal header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-warm-flame", {active: headerBackgroundColor === 'bg-warm-flame header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-warm-flame header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-night-fade", {active: headerBackgroundColor === 'bg-night-fade header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-night-fade header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-sunny-morning", {active: headerBackgroundColor === 'bg-sunny-morning header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-sunny-morning header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-tempting-azure", {active: headerBackgroundColor === 'bg-tempting-azure header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-tempting-azure header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-amy-crisp", {active: headerBackgroundColor === 'bg-amy-crisp header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-amy-crisp header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-heavy-rain", {active: headerBackgroundColor === 'bg-heavy-rain header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-heavy-rain header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-mean-fruit", {active: headerBackgroundColor === 'bg-mean-fruit header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-mean-fruit header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-malibu-beach", {active: headerBackgroundColor === 'bg-malibu-beach header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-malibu-beach header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-deep-blue", {active: headerBackgroundColor === 'bg-deep-blue header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-deep-blue header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-ripe-malin", {active: headerBackgroundColor === 'bg-ripe-malin header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-ripe-malin header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-arielle-smile", {active: headerBackgroundColor === 'bg-arielle-smile header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-arielle-smile header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-plum-plate", {active: headerBackgroundColor === 'bg-plum-plate header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-plum-plate header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-happy-fisher", {active: headerBackgroundColor === 'bg-happy-fisher header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-happy-fisher header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-happy-itmeo", {active: headerBackgroundColor === 'bg-happy-itmeo header-text-dark'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-happy-itmeo header-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-mixed-hopes", {active: headerBackgroundColor === 'bg-mixed-hopes header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-mixed-hopes header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-strong-bliss", {active: headerBackgroundColor === 'bg-strong-bliss header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-strong-bliss header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-grow-early", {active: headerBackgroundColor === 'bg-grow-early header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-grow-early header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-love-kiss", {active: headerBackgroundColor === 'bg-love-kiss header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-love-kiss header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-premium-dark", {active: headerBackgroundColor === 'bg-premium-dark header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-premium-dark header-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-happy-green", {active: headerBackgroundColor === 'bg-happy-green header-text-light'})}
        //                                     onClick={() => toggleHeaderBackgroundColor("bg-happy-green header-text-light")}/>
        //                             </div>
        //                         </ListGroupItem>
        //                     </ListGroup>
        //                 </div>
        //                 <h3 className="themeoptions-heading">
        //                     <div>Opciones de barra lateral</div>
        //                     <Button size="sm" color="focus"
        //                             className={cx("btn-pill btn-shadow btn-wide ml-auto", {active: backgroundColor === ''})}
        //                             onClick={() => toggleBackgroundColor("")}>
        //                         Restaurar Predeterminado
        //                     </Button>
        //                 </h3>
        //                 <div className="p-3">
        //                     <ListGroup>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnableBackgroundImage}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enableBackgroundImage,
        //                                                 'switch-off': !enableBackgroundImage
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Imagen de fondo de la barra lateral
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             ¡Habilite las imágenes de fondo para la barra lateral!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enableBackgroundImage,
        //                                         'd-none': !enableBackgroundImage
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnableSidebarShadow}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enableSidebarShadow,
        //                                                 'switch-off': !enableSidebarShadow
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Sombra de barra lateral
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             ¡Agrega una sombra para la barra lateral!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enableSidebarShadow,
        //                                         'd-none': !enableSidebarShadow
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <h5 className="pb-2">Elegir esquema de color</h5>
        //                             <div className="theme-settings-swatches">
        //                                 <div
        //                                     className={cx("swatch-holder bg-primary", {active: backgroundColor === 'bg-primary sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-primary sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-secondary", {active: backgroundColor === 'bg-secondary sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-secondary sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-success", {active: backgroundColor === 'bg-success sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-success sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-info", {active: backgroundColor === 'bg-info sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-info sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-warning", {active: backgroundColor === 'bg-warning sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-warning sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-danger", {active: backgroundColor === 'bg-danger sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-danger sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-light", {active: backgroundColor === 'bg-light sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-light  sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-dark", {active: backgroundColor === 'bg-dark sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-dark sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-focus", {active: backgroundColor === 'bg-focus sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-focus sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-alternate", {active: backgroundColor === 'bg-alternate sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-alternate sidebar-text-light")}/>
        //
        //                                 <div className="divider"/>
        //
        //                                 <div
        //                                     className={cx("swatch-holder bg-vicious-stance", {active: backgroundColor === 'bg-vicious-stance sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-vicious-stance sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-midnight-bloom", {active: backgroundColor === 'bg-midnight-bloom sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-midnight-bloom sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-night-sky", {active: backgroundColor === 'bg-night-sky sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-night-sky sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-slick-carbon", {active: backgroundColor === 'bg-slick-carbon sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-slick-carbon sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-asteroid", {active: backgroundColor === 'bg-asteroid sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-asteroid sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-royal", {active: backgroundColor === 'bg-royal sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-royal sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-warm-flame", {active: backgroundColor === 'bg-warm-flame sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-warm-flame sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-night-fade", {active: backgroundColor === 'bg-night-fade sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-night-fade sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-sunny-morning", {active: backgroundColor === 'bg-sunny-morning sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-sunny-morning sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-tempting-azure", {active: backgroundColor === 'bg-tempting-azure sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-tempting-azure sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-amy-crisp", {active: backgroundColor === 'bg-amy-crisp sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-amy-crisp sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-heavy-rain", {active: backgroundColor === 'bg-heavy-rain sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-heavy-rain sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-mean-fruit", {active: backgroundColor === 'bg-mean-fruit sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-mean-fruit sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-malibu-beach", {active: backgroundColor === 'bg-malibu-beach sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-malibu-beach sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-deep-blue", {active: backgroundColor === 'bg-deep-blue sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-deep-blue sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-ripe-malin", {active: backgroundColor === 'bg-ripe-malin sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-ripe-malin sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-arielle-smile", {active: backgroundColor === 'bg-arielle-smile sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-arielle-smile sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-plum-plate", {active: backgroundColor === 'bg-plum-plate sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-plum-plate sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-happy-fisher", {active: backgroundColor === 'bg-happy-fisher sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-happy-fisher sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-happy-itmeo", {active: backgroundColor === 'bg-happy-itmeo sidebar-text-dark'})}
        //                                     onClick={() => toggleBackgroundColor("bg-happy-itmeo sidebar-text-dark")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-mixed-hopes", {active: backgroundColor === 'bg-mixed-hopes sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-mixed-hopes sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-strong-bliss", {active: backgroundColor === 'bg-strong-bliss sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-strong-bliss sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-grow-early", {active: backgroundColor === 'bg-grow-early sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-grow-early sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-love-kiss", {active: backgroundColor === 'bg-love-kiss sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-love-kiss sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-premium-dark", {active: backgroundColor === 'bg-premium-dark sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-premium-dark sidebar-text-light")}/>
        //                                 <div
        //                                     className={cx("swatch-holder bg-happy-green", {active: backgroundColor === 'bg-happy-green sidebar-text-light'})}
        //                                     onClick={() => toggleBackgroundColor("bg-happy-green sidebar-text-light")}/>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem
        //                             className={cx("theme-settings-swatches", {
        //                                 'd-block': enableBackgroundImage,
        //                                 'd-none': !enableBackgroundImage
        //                             })}>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Opacidad de fondo
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-right">
        //                                         <ButtonGroup size="sm">
        //                                             <Button
        //                                                 className={cx("btn-shadow opacity-3", {active: backgroundImageOpacity === 'opacity-04'})}
        //                                                 color="primary"
        //                                                 onClick={() => toggleBackgroundImageOpacity("opacity-04")}
        //                                             >4%</Button>
        //                                             <Button
        //                                                 className={cx("btn-shadow opacity-4", {active: backgroundImageOpacity === 'opacity-06'})}
        //                                                 color="primary"
        //                                                 onClick={() => toggleBackgroundImageOpacity("opacity-06")}
        //                                             >6%</Button>
        //                                             <Button
        //                                                 className={cx("btn-shadow opacity-5", {active: backgroundImageOpacity === 'opacity-08'})}
        //                                                 color="primary"
        //                                                 onClick={() => toggleBackgroundImageOpacity("opacity-08")}
        //                                             >8%</Button>
        //                                             <Button
        //                                                 className={cx("btn-shadow opacity-6", {active: backgroundImageOpacity === 'opacity-1'})}
        //                                                 color="primary"
        //                                                 onClick={() => toggleBackgroundImageOpacity("opacity-1")}
        //                                             >10%</Button>
        //                                             <Button
        //                                                 className={cx("btn-shadow opacity-7", {active: backgroundImageOpacity === 'opacity-15'})}
        //                                                 color="primary"
        //                                                 onClick={() => toggleBackgroundImageOpacity("opacity-15")}
        //                                             >15%</Button>
        //                                             <Button
        //                                                 className={cx("btn-shadow opacity-8", {active: backgroundImageOpacity === 'opacity-2'})}
        //                                                 color="primary"
        //                                                 onClick={() => toggleBackgroundImageOpacity("opacity-2")}
        //                                             >20%</Button>
        //                                         </ButtonGroup>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem
        //                             className={cx("theme-settings-swatches", {
        //                                 'd-block': enableBackgroundImage,
        //                                 'd-none': !enableBackgroundImage
        //                             })}>
        //                             <h5>Fondo de la imagen de la barra lateral</h5>
        //                             <div className="divider"/>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar6})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar6)}>
        //                                     <img alt=" " src={sideBar6}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar7})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar7)}>
        //                                     <img alt=" " src={sideBar7}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar8})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar8)}>
        //                                     <img alt=" " src={sideBar8}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar9})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar9)}>
        //                                     <img alt=" " src={sideBar9}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar10})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar10)}>
        //                                     <img alt=" " src={sideBar10}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar1})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar1)}>
        //                                     <img alt=" " src={sideBar1}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar2})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar2)}>
        //                                     <img alt=" " src={sideBar2}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar3})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar3)}>
        //                                     <img alt=" " src={sideBar3}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar4})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar4)}>
        //                                     <img alt=" " src={sideBar4}/>
        //                                 </button>
        //                             </div>
        //                             <div
        //                                 className={cx("swatch-holder swatch-holder-img", {active: backgroundImage === sideBar5})}>
        //                                 <button className="img-holder switch-trigger"
        //                                         onClick={() => toggleBackgroundImage(sideBar5)}>
        //                                     <img alt=" " src={sideBar5}/>
        //                                 </button>
        //                             </div>
        //                         </ListGroupItem>
        //                     </ListGroup>
        //                 </div>
        //                 <h3 className="themeoptions-heading">
        //                     <div>Opciones de contenido principal</div>
        //                     <Button size="sm" color="focus"
        //                             className={cx("btn-pill btn-shadow btn-wide ml-auto", {active: colorScheme === 'white'})}
        //                             onClick={() => toggleColorScheme("white")}>
        //                         Restaurar Predeterminado
        //                     </Button>
        //                 </h3>
        //                 <div className="p-3">
        //                     <ListGroup>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnablePageTitleIcon}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enablePageTitleIcon,
        //                                                 'switch-off': !enablePageTitleIcon
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Icono de título de página
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             ¡Activa el cuadro de iconos para los títulos de las páginas!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enablePageTitleIcon,
        //                                         'd-none': !enablePageTitleIcon
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnablePageTitleSubheading}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enablePageTitleSubheading,
        //                                                 'switch-off': !enablePageTitleSubheading
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Título de la página Descripción
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             ¡Habilite la descripción debajo del título de la página!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enablePageTitleSubheading,
        //                                         'd-none': !enablePageTitleSubheading
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <div className="widget-content p-0">
        //                                 <div className="widget-content-wrapper">
        //                                     <div className="widget-content-left mr-3">
        //                                         <div className="switch has-switch" data-on-label="ON"
        //                                              data-off-label="OFF"
        //                                              onClick={toggleEnablePageTabsAlt}>
        //                                             <div className={cx("switch-animate", {
        //                                                 'switch-on': enablePageTabsAlt,
        //                                                 'switch-off': !enablePageTabsAlt
        //                                             })}>
        //                                                 <input type="checkbox"/><span
        //                                                 className="switch-left bg-success">ON</span><label>&nbsp;</label><span
        //                                                 className="switch-right bg-success">OFF</span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="widget-content-left">
        //                                         <div className="widget-heading">
        //                                             Pestañas de sombra de sección de página
        //                                         </div>
        //                                         <div className="widget-subheading">
        //                                             ¡Habilite un estilo alternativo para las pestañas de secciones de
        //                                             página!
        //                                         </div>
        //                                     </div>
        //                                     <div className={cx("widget-content-right text-success opacity-6", {
        //                                         'd-block': enablePageTabsAlt,
        //                                         'd-none': !enablePageTabsAlt
        //                                     })}>
        //                                         <FontAwesomeIcon size="2x" icon={faCheck}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </ListGroupItem>
        //                         <ListGroupItem>
        //                             <h5 className="pb-2">Esquemas de colores claros</h5>
        //                             <div className="theme-settings-swatches">
        //                                 <ButtonGroup className="mt-2">
        //                                     <Button
        //                                         className={cx("btn-wide btn-shadow btn-primary", {active: colorScheme === 'white'})}
        //                                         onClick={() => toggleColorScheme("white")}>Tema blanco</Button>
        //                                     <Button
        //                                         className={cx("btn-wide btn-shadow btn-primary", {active: colorScheme === 'gray'})}
        //                                         onClick={() => toggleColorScheme("gray")}>Tema gris</Button>
        //                                 </ButtonGroup>
        //                             </div>
        //                         </ListGroupItem>
        //                     </ListGroup>
        //                 </div>
        //             </div>
        //         </PerfectScrollbar>
        //     </div>
        // </div>
    )
}

const mapStateToProps = state => ({
    backgroundColor: state.ThemeOptions.backgroundColor,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,

    colorScheme: state.ThemeOptions.colorScheme,

    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,


    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    backgroundImage: state.ThemeOptions.backgroundImage,

    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity
})

const mapDispatchToProps = dispatch => ({
    setEnableBackgroundImage: enable => dispatch(setEnableBackgroundImage(enable)),

    setEnableFixedHeader: enable => dispatch(setEnableFixedHeader(enable)),
    setEnableHeaderShadow: enable => dispatch(setEnableHeaderShadow(enable)),
    setEnableSidebarShadow: enable => dispatch(setEnableSidebarShadow(enable)),
    setEnableFixedFooter: enable => dispatch(setEnableFixedFooter(enable)),
    setEnableFixedSidebar: enable => dispatch(setEnableFixedSidebar(enable)),

    setEnablePageTitleIcon: enable => dispatch(setEnablePageTitleIcon(enable)),
    setEnablePageTitleSubheading: enable => dispatch(setEnablePageTitleSubheading(enable)),
    setEnablePageTabsAlt: enable => dispatch(setEnablePageTabsAlt(enable)),

    setBackgroundImage: image => dispatch(setBackgroundImage(image)),

    setColorScheme: color => dispatch(setColorScheme(color)),

    setBackgroundColor: color => dispatch(setBackgroundColor(color)),

    setHeaderBackgroundColor: color => dispatch(setHeaderBackgroundColor(color)),

    setBackgroundImageOpacity: color => dispatch(setBackgroundImageOpacity(color))
})

export default connect(mapStateToProps, mapDispatchToProps)(ThemeOptions)
