import React, {Fragment, useState, useEffect, useLayoutEffect} from "react"
import {Link, Redirect} from "react-router-dom"
import Slider from "react-slick"
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap'
import {faUser} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import gql from 'graphql-tag'
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda'

import bg1 from '../../assets/utils/images/originals/bg1.jpg'
import bg2 from '../../assets/utils/images/originals/bg2.jpg'
import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import {useAuth} from "../../Context"
import {isToken} from "../../utils/scripts"

const Login = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }
    const {auth, client, setClient, toast} = useAuth()
    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const [expRight, setExpRight] = useState(false)

    const handleChange = ({target}) => {
        const {name, value} = target
        setUser({
            ...user,
            [name]: value
        })
    }

    const postLogin = () => {
        const {email, password} = user
        setExpRight(true)
        client.query({
            query: gql`query createToken($email: String!, $password: String!) {
              login(email: $email, password: $password) {
                authentication
                layout
              }
            }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                email,
                password
            },
            fetchPolicy: "no-cache"
        }).then(response => {
            const {login} = response.data
            setExpRight(false)
            if (login === null) {
                toast.error('Credenciales incorrectas', {autoClose: 2000})
                return
            }
            const {authentication} = login
            if (authentication === 'verify') {
                toast.error('Aún no ha verificado su email!', {autoClose: 10000})
                return
            }
            setClient(login)
        }).catch(({message}) => {
            toast.error(message, {autoClose: 2000})
            setExpRight(false)
        })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter")
                postLogin()
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [user])

    useLayoutEffect(() => {
        if(!(auth.authentication)){
            localStorage.clear()
            sessionStorage.clear()
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            })

            localStorage.setItem('Auth', JSON.stringify({}))
            localStorage.setItem('Auth2', JSON.stringify({}))
            localStorage.setItem('Company', JSON.stringify({}))
            localStorage.setItem('localempresa', JSON.stringify({}))
        }

    }, [])

    return (
        auth.authentication ?
            <Redirect to={'/home'}/>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="8" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg1 + ')'}}/>
                                        <div className="slider-content">
                                            <h3>Consulta de Validez de CPE</h3>
                                            <p>Consulte la validez de boletas, facturas y sus respectivas notas
                                                electrónicas.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg3 + ')'}}/>
                                        <div className="slider-content">
                                            <h3>Pago masivo de detracciones con NPD</h3>
                                            <p>Operaciones SOL para pago masivo.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta DNI, RUC y EsSalud</h3>
                                            <p>Consultas de reniec, sunat y essalud sin capcha.</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    <img src="/logo.png" alt=""/>
                                    <h4 className="mb-0">
                                        <div>Bienvenido,</div>
                                        <span>Inicia sesión en tu cuenta.</span>
                                    </h4>
                                </div>
                                <Row className="divider"/>
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Email</Label>
                                                <Input type="email" name="email" value={user.email}
                                                       onChange={handleChange}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="examplePassword">Contraseña</Label>
                                                <Input type="password" name="password" value={user.password}
                                                       onChange={handleChange}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup check>
                                        <Input type="checkbox" name="check" id="exampleCheck"/>
                                        <Label for="exampleCheck" check>Mantenme conectado</Label>
                                    </FormGroup>
                                </Form>
                                <Row className="divider"/>
                                <div className="d-flex align-items-center">
                                    <div className="ml-auto">
                                        <Link to="/password/reset" className="btn-lg btn btn-link">¿Olvidó su
                                            contraseña?</Link>
                                        <LaddaButton className="btn btn-pill btn-primary" loading={expRight}
                                                     onClick={postLogin}
                                                     data-style={EXPAND_RIGHT}>
                                            <FontAwesomeIcon icon={faUser}/> Ingresar
                                        </LaddaButton>
                                    </div>
                                </div>

                                <h6 className="mt-3">
                                    ¿Sin cuenta?{' '}
                                    <Link to={'/register'} className="text-primary">Regístrate ahora</Link>
                                </h6>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default Login