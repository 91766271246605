import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import MetisMenu from 'react-metismenu'
import jwt from 'jsonwebtoken'

import {useAuth} from "../../Context"
import {
    HomeNav, ConsultasNav, PDTsNav, ProcesosNav, SlePle51Nav, AdminNav, LrContablesNav, CompraVentaNav,
    SisContSimpNav, GeneradorPdf, SireNav
} from "./navItems"

const Nav = () => {
    const {auth, setAuth} = useAuth()

    if (!auth.authentication) {
        setAuth({})
        window.location.href = '/login'
    }

    const {rol} = jwt.decode(auth.authentication)

    return (
        <Fragment>
            <MetisMenu content={HomeNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>Sistema Contable Simplificado</h5>
            <MetisMenu content={SisContSimpNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>SIRE SUNAT</h5>
            <MetisMenu content={SireNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>Consultas Online</h5>
            <MetisMenu content={ConsultasNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>Procesos SOL</h5>
            <MetisMenu content={ProcesosNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>PDT's</h5>
            <MetisMenu content={PDTsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}> IMPORTACIÓN MASIVA XML </h5>
            <MetisMenu content={CompraVentaNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}> GENERADOR PDF </h5>
            <MetisMenu content={GeneradorPdf} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>LIBROS Y REGISTROS CONTABLES</h5>
            <MetisMenu content={LrContablesNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>LIBROS ELECTRÓNICOS (SLE-PLE 5.2)</h5>
            <MetisMenu className="vertical-nav-menu" content={SlePle51Nav} activeLinkFromLocation iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            {
                rol === 1 ? <Fragment>
                    <h5 className={`app-sidebar__heading ${AdminNav.length === 0 ? 'hidden' : ''}`}>ADMINISTRADOR</h5>
                    <MetisMenu content={AdminNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                               classNameStateIcon="pe-7s-angle-down"
                    />
                </Fragment> : <></>
            }
        </Fragment>
    )
}

export default withRouter(Nav)
