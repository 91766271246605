import React, {Fragment, useEffect, useState} from 'react'
import cx from 'classnames'
import {connect} from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {DropdownToggle, UncontrolledDropdown, Modal, Col, Row} from 'reactstrap'
import Ionicon from "react-ionicons"
import moment from "moment"
import 'moment/locale/es'

import HeaderLogo from '../AppLogo'
import UserBox from './Components/UserBox'
import {useAuth} from "../../Context"
import {decodeToken} from "../../utils/scripts"

const Header = props => {
    let {headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow} = props
    const {localEmpresa, companySire, auth} = useAuth()
    const [modal, setModal] = useState(false)

    const tutoriales = () => {
        const {pathname} = window.location

        let url = ''
        switch (pathname) {
            case '/aplicativos/tipocambio':
                url = 'https://www.youtube.com/embed/p3llUBBqgco'
                break
            case '/aplicativos/remype':
                url = 'https://www.youtube.com/embed/y7fzF_ccbrQ'
                break
            case '/aplicativos/essalud':
                url = 'https://www.youtube.com/embed/aj1qAxk6QYU'
                break
            case '/aplicativos/dnibasico':
                url = 'https://www.youtube.com/embed/s8c88PVxDxk'
                break
            case '/aplicativos/dnipremium':
                url = 'https://www.youtube.com/embed/s8c88PVxDxk'
                break
            case '/scs/empresas':
                url = 'https://www.youtube.com/embed/7m0tA5BCd9I'
                break
            case '/scs/data/clientes':
            case '/scs/data/proveedores':
                url = 'https://www.youtube.com/embed/aJJ2Vf88yb0?end=116'
                break
            case '/scs/data/pcge':
                url = 'https://www.youtube.com/embed/aJJ2Vf88yb0?start=116&end=173'
                break
            case '/scs/data/destinos':
                url = 'https://www.youtube.com/embed/aJJ2Vf88yb0?start=173'
                break
            case '/scs/operaciones/compras':
                url = 'https://www.youtube.com/embed/bYpwoycJUlc'
                break
            case '/scs/operaciones/ventas':
                url = 'https://www.youtube.com/embed/_Qa_G69qvRs'
                break
            case '/scs/operaciones/asientosdiarios':
                url = 'https://www.youtube.com/embed/Swfij_O0GgE'
                break
            case '/scs/cuentas/pagar':
                url = 'https://www.youtube.com/embed/4qPX_w9hkvQ?end=193'
                break
            case '/scs/cuentas/cobrar':
                url = 'https://www.youtube.com/embed/4qPX_w9hkvQ?start=193'
                break
            case '/scs/le/compras81':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?end=154'
                break
            case '/scs/le/compras82':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=154&end=205'
                break
            case '/scs/le/ventas141':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=205&end=230'
                break
            case '/scs/le/diario51':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=230&end=326'
                break
            case '/scs/le/pcge53':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=326&end=341'
                break
            case '/scs/le/diario52':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=341&end=363'
                break
            case '/scs/le/pcge54':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=363&end=377'
                break
            case '/scs/le/mayor':
                url = 'https://www.youtube.com/embed/i0BMafM6NFw?start=377'
                break
            case '/scs/lf/compras':
                url = 'https://www.youtube.com/embed/jXNjfAptpGo?end=58'
                break
            case '/scs/lf/ventas':
                url = 'https://www.youtube.com/embed/jXNjfAptpGo?start=58&end=98'
                break
            case '/scs/lf/ldiario':
                url = 'https://www.youtube.com/embed/jXNjfAptpGo?start=98&end=139'
                break
            case '/scs/lf/diario':
                url = 'https://www.youtube.com/embed/jXNjfAptpGo?start=139&end=176'
                break
            case '/scs/lf/mayor':
                url = 'https://www.youtube.com/embed/jXNjfAptpGo?start=176'
                break
            case '/scs/hoja-trabajo':
                url = 'https://www.youtube.com/embed/JVJJzdiGxCM?end=163'
                break
            case '/scs/ef/bgc':
                url = 'https://www.youtube.com/embed/JVJJzdiGxCM?start=163&end=257'
                break
            case '/scs/ef/ern':
                url = 'https://www.youtube.com/embed/JVJJzdiGxCM?start=257&end=346'
                break
            case '/scs/ef/erf':
                url = 'https://www.youtube.com/embed/JVJJzdiGxCM?start=346'
                break
            case '/scs/utilitarios/importar81':
                url = 'https://www.youtube.com/embed/7773DpwlvWk'
                break
            case '/scs/utilitarios/importar141':
                url = 'https://www.youtube.com/embed/y96eBKO6IhA'
                break
            case '/scs/utilitarios/importar51':
                url = 'https://www.youtube.com/embed/w-e1EeOgPlM'
                break
            case '/aplicativos/validez/cpe':
                url = 'https://www.youtube.com/embed/O4Md-pQVIOc?end=727'
                break
            case '/aplicativos/validez/xml':
                url = 'https://www.youtube.com/embed/O4Md-pQVIOc?start=727'
                break
            case '/operaciones/detraccion/provclie':
            case '/operaciones/detraccion/clieprov':
                url = 'https://www.youtube.com/embed/ByFmIHyB2wY'
                break
            default:
                url = ''
                break
        }
        url += url.includes('?') ? '&autoplay=1' : '?autoplay=1'

        if (url !== '?autoplay=1')
            return <div className="header-dots">
                <UncontrolledDropdown>
                    <DropdownToggle className="p-0 mr-2" color="link" onClick={() => setModal(!modal)}>
                        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                            <div className="icon-wrapper-bg bg-warning"/>
                            <Ionicon beat={true} color="#d92550" fontSize="23px" icon="logo-youtube"/>
                        </div>
                    </DropdownToggle>
                </UncontrolledDropdown>

                <Modal isOpen={modal} size="lg" toggle={() => setModal(!modal)} className={props.className}>
                    <iframe width="100%" height="550" src={`${url}`} frameBorder="0"/>
                </Modal>
            </div>
    }

    const selectEmpresa = () => {
        const {pathname} = window.location
        const vEmpresa = pathname.includes('/scs/')
        const sireEmpresa = pathname.includes('/sire/')

        return (
            <Fragment>
                {vEmpresa && localEmpresa && localEmpresa.ruc ?
                    <Row className="text-center">
                        <Col sm="12" md="12" xl="12">
                            <b>{localEmpresa.ruc} - {localEmpresa.razon_social} - {moment(localEmpresa.periodo).locale("es").format("MMMM YYYY").toUpperCase()}</b>
                        </Col>
                    </Row> : <></>
                }
                {sireEmpresa && companySire && companySire.ruc ?
                    <Row className="text-center">
                        <Col sm="12" md="12" xl="12">
                            <b>{companySire.ruc} - {companySire.razon_social} - {moment(companySire.period).locale("es").format("MMMM YYYY").toUpperCase()}</b>
                        </Col>
                    </Row> : <></>
                }
            </Fragment>
        )
    }

    useEffect(() => {
        // let {email, id, sub, role} = decodeToken(auth),
        let {email, id, sub, role} = decodeToken(auth.authentication),
            Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date()

        Tawk_API.embedded = `tawk_${process.env.REACT_APP_PROPERTYID}`;

        (() => {
            let s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0]
            s1.async = true
            s1.src = `https://embed.tawk.to/${process.env.REACT_APP_PROPERTYID}/${process.env.REACT_APP_TAWKID}`
            s1.charset = 'UTF-8'
            s1.setAttribute('crossorigin', '*')
            s0.parentNode.insertBefore(s1, s0)
        })();

        window.Tawk_API = window.Tawk_API || {}

        window.Tawk_API.visitor = {
            id: id,
            name: sub,
            enterprise: localEmpresa.razon_social,
            rucEmpresa: localEmpresa.ruc,
            email: email,
            role: role
        }
        window.Tawk_API.onLoad = function () {
            window.Tawk_API.addTags([`Rol del Usuario : ${role}`, `Razon Social : ${localEmpresa.razon_social}`, `Ruc : ${localEmpresa.ruc}`], function (error) {
            })
        }

        window.Tawk_API.onLoad = function () {
            window.Tawk_API.setAttributes({
                'idUsuario': id,
                'rolUsuario': role,
                'razonEmpresa': localEmpresa.razon_social,
                'rucEmpresa': localEmpresa.ruc,
            }, function (error) {
            })
        }

        window.Tawk_API.onAgentLeaveChat = function () {
            window.Tawk_API.endChat()
        }
    }, [])

    return (
        <ReactCSSTransitionGroup component="div"
                                 className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                                 transitionName="HeaderAnimation" transitionAppear={true}
                                 transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>
            <HeaderLogo/>
            <div className={cx("app-header__content", {'header-mobile-open': enableMobileMenuSmall})}>
                <div className="app-header-right">
                    {selectEmpresa()}
                    {tutoriales()}
                    <UserBox/>
                </div>
            </div>
        </ReactCSSTransitionGroup>
    )
}

const mapStateToProps = ({ThemeOptions}) => ({
    enableHeaderShadow: ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: ThemeOptions.enableMobileMenuSmall,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
